import { ProvidingItemQuantityBehaviour, ProvidingItemSubtype, ProvidingItemTaskBehaviour } from "constants/ProvidingItemConstants";
import { RoundingMethod } from "constants/RoundingMethod";
import { Unit } from "constants/Unit";
import { UnitType } from "constants/UnitType";
import { isEmpty, isNumber, range, sum } from 'lodash';
import { computed, observable } from "mobx";
import { fromResource } from 'mobx-utils';
import { ProvidedQuantity } from "models/ProvidedQuantity";
import moment from 'moment';
import { SKIP, custom, map, object, serializable } from "serializr";
import { getPriceWithFees, getPriceWithMerchantAdjustment, roundPrice, sumPrices } from 'utils/CurrencyUtil';
import DbItemFactory from 'utils/DbItemFactory';
import { firestore } from 'utils/FirebaseInitializedApp';
import MeasurementFormatter, { getFormulaVariables } from "utils/MeasurementFormatter";
import { MEASUREMENT_TREENODE_SEPARATOR } from "utils/MeasurementUtil";
import { getBaseQuantities } from 'utils/ProvidedQuantityUtil';
import { assignToExistingObject } from 'utils/StoreUtil';
import { formatUnit } from 'utils/UnitFormatter';
import { getSafe, round } from 'utils/Utils';
import i18n from "utils/i18n";
import { localized } from "utils/localized";
import Fee from "./Fee";
import MeasurementValue from "./MeasurementValue";
import Merchant from './Merchant';
import ModelBaseWithCategory from "./ModelBaseWithCategories";
import ProvidingItemMeta from './ProvidingItemMeta';

// since there are a lot of items, could be possible to only load description on demand
class ProvidingItem extends ModelBaseWithCategory {
  @observable isLoading = false;
  @serializable @observable type = 'ProvidingItem';
  @serializable @observable subtype: ProvidingItemSubtype = ProvidingItemSubtype.Material;
  @serializable @observable markupPercent: number = 0; // not supported yet should override projet fees
  @serializable @observable _wastePercent: number = this.stores?.userInfoStore?.user?.defaultWastePercent || 5;

  shouldCheckMinMaxUserVersion = true;

  @serializable @observable reportQuantityBehaviour = ProvidingItemQuantityBehaviour.Default;

  // legacy
  @serializable @observable shouldHideRelatedTasksInReport = false;

  // not 100% tested with kendo pdf shouldShowTasks option, but better that not having the option at all
  @serializable @observable _reportTaskBehaviour = ProvidingItemTaskBehaviour.Default;

  @computed get reportTaskBehaviour() {
    if (this.shouldHideRelatedTasksInReport) {
      return ProvidingItemTaskBehaviour.AlwaysHide;
    }

    return this._reportTaskBehaviour;
  }

  set reportTaskBehaviour(value: ProvidingItemTaskBehaviour) {
    if (this.shouldHideRelatedTasksInReport) {
      this.shouldHideRelatedTasksInReport = false;
    }

    this._reportTaskBehaviour = value;
  }

  @serializable @computed get canShowQuantitiesInReport() {
    return this.reportQuantityBehaviour != ProvidingItemQuantityBehaviour.AlwaysHide
  };
  set canShowQuantitiesInReport(value: boolean) {
    if (value === false) {
      this.reportQuantityBehaviour = ProvidingItemQuantityBehaviour.AlwaysHide;
    }
  }

  @serializable @observable isExemptFromAllFees = false;

  @localized priceFormula: string = '';

  @serializable previousPrice: number = 0;

  @localized url: string = '';

  @serializable @observable _sku = '';

  // for items syncing with equipment rental stores
  @serializable @observable rentalTimeUnit = Unit.Day;

  @computed get merchant(): Merchant {
    return this.stores.merchantsStore.getMerchantByUrl(this.url);
  }

  @computed get merchantSku(): string {
    return this._sku || this.merchant && this.id.split(':')?.[1] || '';
  }

  set merchantSku(value: string) {
    this._sku = value;
  }

  @serializable @observable minimumPrice = 0;

  // to calibrate vs merchant raw price
  @serializable @observable _priceAdjustmentPercent = 0;

  @computed get priceAdjustmentPercent() {
    return (
      this.merchant &&
      (!this.isLabour || getSafe(() => this.labourRate === parseFloat(this.priceFormula)))
    )
      ? this._priceAdjustmentPercent
      : 0;
  }

  set priceAdjustmentPercent(value: number) {
    this._priceAdjustmentPercent = value;
  }

  @serializable(custom(
    // doesn't get saved to db, not sure why needs to be read
    () => SKIP,
    (jsonValue, context, oldValue, callback) => {
      if (!context.json.priceFormula) {
        callback(null, jsonValue);
      }
    })) @computed get price(): number {
      if (!this.dynamicPriceMeasurementValue) {
        // 99.9% of cases
        return parseFloat(this.priceFormula) || 0;
      }

      // special cases when price depends on a global variable like Price of a pound of metal
      // hackish and should be converted to use real formula parser
      const floatPart = parseFloat(this.priceFormula.replace(/[^0-9\.]/g, ''));

      return MeasurementFormatter.getValueWithoutUnit(this.dynamicPriceMeasurementValue) * floatPart;
    }

  // Make sure to set priceUpdatedMiliseconds if changing price for a new value
  set price(value: number) {
    if (!isNumber(value)) {
      value = 0;
    }

    if (isEmpty(Object.keys(this._priceFormula))) {
      this.priceFormula = value.toString();
    } else {
      Object.keys(this._priceFormula).forEach(lang => {
        this._priceFormula[lang] = value.toString();
      })
    }
  }

  @computed get priceWithPriceAdjustment() {
    return getPriceWithMerchantAdjustment(this.price, this);
  }


  // legacy variable, computed values still needed
  @observable _canBeRounded: boolean = true;

  @computed @serializable get canBeRounded(): boolean {
    const { settingsStore } = this.stores;
    if (
      this.isLabour &&
      settingsStore?.settings?.shouldRoundLabour === false &&
      this._roundingMethod === RoundingMethod.Unspecified
    ) {
      return false;
    }

    if (this._roundingMethod === RoundingMethod.Unspecified) {
      return this._canBeRounded;
    }

    return (
      this._roundingMethod === RoundingMethod.RoundByProject ||
      this._roundingMethod === RoundingMethod.RoundByTask
    );
  }

  set canBeRounded(value: boolean) {
    this._canBeRounded = value;
  }
  //---

  @observable @serializable _roundingMethod = RoundingMethod.Unspecified;

  @computed get roundingMethod(): RoundingMethod {
    const { settingsStore } = this.stores;

    // legacy
    if (this._roundingMethod === RoundingMethod.Unspecified) {
      return this.canBeRounded
        ? (settingsStore?.settings?.defaultRoundingMethod || RoundingMethod.RoundByProject)
        : RoundingMethod.DontRound;
    }

    return this._roundingMethod;
  }

  set roundingMethod(value: RoundingMethod) {
    this._roundingMethod = value;

    this.canBeRounded = value !== RoundingMethod.DontRound;
  }

  // not used when updating price manually, but should be
  // would be nice to have generic way of tracking updated date by field
  // easier to merge master -> user -> project without overriding items completely
  // also moment treats 0 miliseconds date like date now
  @serializable @observable priceUpdatedMiliseconds: number = new Date('2010-01-01').getTime();

  @computed get priceUpdated(): moment.Moment {
    // listen to locale
    const locale = i18n.locale;

    return moment(this.priceUpdatedMiliseconds || moment().valueOf());
  }

  @localized name: string = i18n.t('Nouvel item');

  @localized unitString: string = 'unit';

  // string to show in big list
  @computed get computedUnitString() {
    const timeProvidedQuantity = this.providedQuantities.get(UnitType.Time);
    const timeQuantity = timeProvidedQuantity?.quantity || '';
    const timeUnit = timeProvidedQuantity?.unit || '';

    let mainUnit = formatUnit(
      Array.from(this.providedQuantities)
        .find(([key, value]) => (
          value.unit !== Unit.Unit && value.quantity === 1
        ))?.[1]?.unit
    ) || '';

    const secondaryUnit = (this.isLabour && !!this.labourRate && timeQuantity !== 1 && timeUnit)
      ? ' ' + i18n.t('or') + ' ' + round(this.labourRate, 0) + ' $/' + formatUnit(timeUnit)
      : '';

    if (!mainUnit && secondaryUnit) {
      mainUnit = formatUnit(Unit.Unit);
    }

    return mainUnit + secondaryUnit;
  }

  @computed get isMaterial(): boolean {
    return this.subtype === ProvidingItemSubtype.Material;
  }

  @computed get isLabour(): boolean {
    return !this.isMaterial;
  }

  @computed get isDynamicName(): boolean {
    return (
      this.name.includes('{') &&
      this.name.includes('}')
    );
  }

  // should separate behavior between name and price, shouldnt check dynamic name
  @computed get isDynamicPrice(): boolean {
    return this.isDynamicName || (
      this.priceFormula.includes("'") 
    );
  }

  // only use this to get price when item is NOT attached to a task
  // semi duplicate with Task, but different when no task context
  @computed get dynamicPriceMeasurementValue(): MeasurementValue {
    const { treeNodesStore } = this.stores;

    // only supports formula that looks exactly like 'Measurement Name' * 00.00
    // should eventually use the real parser like MeasurementValue
    let variableName = getSafe(() => getFormulaVariables(this.priceFormula)[0]);

    if (!variableName || !variableName.includes(MEASUREMENT_TREENODE_SEPARATOR)) {
      return null;
    }

    const variableNameTokens = variableName.split(MEASUREMENT_TREENODE_SEPARATOR);
    const treeNodeName = variableNameTokens[0];
    variableName = variableNameTokens[1];

    const treeNode = treeNodesStore.getItemByName(treeNodeName);

    if (!treeNode) {
      return null;
    }

    const variableMeasurementValue = treeNode?.measurementValuesArray
      .find(measurementValue => variableName === measurementValue?.measurement?.name);

    return variableMeasurementValue;
  }

  @computed get isTrialLocked(): boolean {
    const { providingItemsStore, subscriptionsStore } = this.stores;
    if (!subscriptionsStore.isTrial) {
      return false;
    }

    return !providingItemsStore.trialItems.has(this.id);
  }

  @computed get timeQuantity(): number {
    // Special case: we need 15 hours and price is required time is 5h for one activity (eg. replace a window),
    // we still want to dislay 15h not 3 packs
    // not very well implemented
    return getSafe(() => this.providedQuantities.get(UnitType.Time).quantity) || 1;
  }

  @computed get applicableFees(): Fee[] {
    const { settingsStore } = this.stores;

    if (this.isExemptFromAllFees) {
      return [];
    }

    // every field is AND
    return (settingsStore.settings?.fees || [])
      .filter(fee => (
        (isEmpty(fee.providingItemsSubtypes) || fee.providingItemsSubtypes.includes(this.subtype)) &&
        (isEmpty(fee.providingItemsCategoryIds) || fee.providingItemsCategoryIds.includes(this.category?.id)) &&
        (isEmpty(fee.providingItemsSubcategoryIds) || fee.providingItemsSubcategoryIds.includes(this.subcategory?.id)) &&
        (isEmpty(fee.providingItemsIds) || fee.providingItemsIds.includes(this.id)) &&

        (isEmpty(fee.excludedProvidingItemsSubtypes) || !fee.excludedProvidingItemsSubtypes.includes(this.subtype)) &&
        (isEmpty(fee.excludedProvidingItemsCategoryIds) || !fee.excludedProvidingItemsCategoryIds.includes(this.category?.id)) &&
        (isEmpty(fee.excludedProvidingItemsSubcategoryIds) || !fee.excludedProvidingItemsSubcategoryIds.includes(this.subcategory?.id)) &&
        (isEmpty(fee.excludedProvidingItemsIds) || !fee.excludedProvidingItemsIds.includes(this.id))
      ));
  }

  getProvidingItemFeesValues(price = this.priceWithPriceAdjustment, shouldRound = true): Map<Fee, number> {
    const { settingsStore } = this.stores;
    const feesValues = new Map();

    this.applicableFees.forEach(fee => {
      const providingItemPriceWithPreviousFees = (shouldRound ? sumPrices : sum)([...Array.from(feesValues.values()), price]);

      const feeValue = (shouldRound ? roundPrice : (i => i))(
        settingsStore.settings.areFeesRecursivelyCalculated
          ? (fee.percentage / 100) * providingItemPriceWithPreviousFees
          : (fee.percentage / 100) * price
      );

      feesValues.set(fee, feeValue);
    });

    return feesValues;
  }


  // should use mostly this field for price, except when in edit dialog and we want to show 
  // the price before merchant adjustment
  @computed get priceWithOrWithoutFees(): number {
    const { settingsStore, routerStore } = this.stores;
    return (settingsStore.settings?.areFeesIncludedInItemPrice && routerStore.isReportPage)
      ? this.priceWithFees
      : this.priceWithPriceAdjustment;
  }

  @computed get priceWithFees(): number {
    return getPriceWithFees(this.priceWithPriceAdjustment, this);
  }

  @computed get wastePercent(): number {
    const { userInfoStore, settingsStore } = this.stores;
    const shouldAllowWasteForLabour = userInfoStore.user?.shouldAllowWasteForLabour || settingsStore?.settings?.shouldAllowWasteForLabour;
    return (this.subtype === ProvidingItemSubtype.Labour && !shouldAllowWasteForLabour)
      ? 0
      : this._wastePercent;
  }

  set wastePercent(value: number) {
    const { userInfoStore, settingsStore } = this.stores;
    const shouldAllowWasteForLabour = userInfoStore.user?.shouldAllowWasteForLabour || settingsStore?.settings?.shouldAllowWasteForLabour;
    if (this.subtype === ProvidingItemSubtype.Labour && !shouldAllowWasteForLabour) {
      return;
    }

    this._wastePercent = value;
  }

  // $ / whatever unit is for providing item of type Time
  @serializable @observable labourRate: number = 0;

  // number of whatever unit is for providing item of type Time
  @computed get labourDuration(): number {
    return getSafe(() => this.providedQuantities.get(UnitType.Time).quantity) || 0;
  }

  set labourDuration(value: number) {
    if (!this.providedQuantities.has(UnitType.Time)) {
      this.providedQuantities.set(
        UnitType.Time,
        new ProvidedQuantity(UnitType.Time, value, Unit.Hour)
      );
    }

    const timeProvidedQuantity = this.providedQuantities.get(UnitType.Time);
    timeProvidedQuantity.quantity = value;
  }

  // separate Metadata is not that useful because prevents indexing description and adds a lot of complexity to code
  // to save a small amount of loading because it would be cached anyway....

  // can't use store  when loading on-demand, because cannot listen to modified objects unless listening to all objects in stores
  metaCached: ProvidingItemMeta = null;
  metaUnsubscribe;

  metaSink = (sink) => {
    const { providingItemsStore, userInfoStore } = this.stores;

    const store = this.stores.providingItemsStore;
    const unsubscribes = [];
    let currentMeta = new ProvidingItemMeta(this.stores);
    const cascadeOrdersReceived = new Set<number>();

    let cascadeOrders = range(0, store.dbLocations.length).reverse();

    // BAD!!!! what does it do??
    if (!userInfoStore.user?.shouldAutoSyncProvidingItems && this.cascadeOrder == providingItemsStore.userCollectionIndex) {
      cascadeOrders = [providingItemsStore.userCollectionIndex];
    }

    // start by highest cascade order, but maximum what the item is
    cascadeOrders.forEach(cascadeOrder => {
      const location = store.dbLocations[cascadeOrder];

      const db = firestore();
      const dbRef = db.collection(location.replace(store.storeKey, store.storeKey + '_meta')).doc(this.id);
      const unsubscribes = [];

      unsubscribes.push(dbRef.onSnapshot(
        doc => {
          const itemData = doc.data();
          if (itemData) {
            itemData.cascadeOrder = cascadeOrder;
            const newMeta = DbItemFactory.create(itemData, this.stores) as ProvidingItemMeta;

            if (currentMeta.cascadeOrder <= newMeta.cascadeOrder) {
              assignToExistingObject(currentMeta, newMeta);
            }
          }

          cascadeOrdersReceived.add(cascadeOrder);

          if (cascadeOrdersReceived.size === cascadeOrders.length) {
            this.metaCached = currentMeta;
            sink(currentMeta);
          }
        }
      ));
    })

    this.metaUnsubscribe = () => unsubscribes.forEach(unsubscribe => unsubscribe());
  }

  metaRef = fromResource(
    this.metaSink,
    () => {
      this.metaUnsubscribe()
    },
    this.metaCached || new ProvidingItemMeta(this.stores)
  );

  // should only be accessed from observer component,
  // otherwise won't be loaded
  @computed get meta() {
    return this.metaRef.current();
  }

  waitOnMetaReady(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.metaCached) {
        resolve();
      }

      this.metaSink((meta) => {
        this.metaCached = meta;
        resolve();
      });
    });
  }

  @observable @serializable(map(object(ProvidedQuantity)))
  providedQuantities: Map<UnitType, ProvidedQuantity> = getBaseQuantities();

  /*
  getProvidedQuantityForUnitType(unitType: UnitType = null) {
    // Keeping this commented block to avoid error of doing this again.
    // It can cause a mobx error of changing observable inside a getter
    // ensure a default providing quantity which is 1 unit
    //if (!quantities.get(UnitType.Unit)) {
    //  quantities.set(UnitType.Unit, new ProvidedQuantity(UnitType.Unit, 1, Unit.Unit));
    //}

    return this.providedQuantities.get(unitType);
  }
  */

  clone(stores = this.stores, newId = this.id): this {
    const copy = super.clone(stores, newId);

    if (this.metaCached) {
      copy.metaCached = this.metaCached.clone();
    }

    return copy;
  }
}

/*function testLocalizer(thing: any) {
  const target = typeof thing === "function" ? thing.prototype : thing;

  debugger;

}

testLocalizer(ProvidingItem);*/

export default ProvidingItem;