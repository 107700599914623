import { AdjustmentType } from 'constants/AdjustmentType';
import { RoundingMethod } from 'constants/RoundingMethod';
import { Unit } from "constants/Unit";
import { UnitType } from "constants/UnitType";
import { compact, identity, round, sumBy } from 'lodash';
import { computed, observable } from "mobx";
import { computedFn } from 'mobx-utils';
import Measurement from "models/Measurement";
import { ProvidedQuantity } from "models/ProvidedQuantity";
import { object, serializable } from "serializr";
import { getPriceWithFees, roundPrice, sumPrices } from "utils/CurrencyUtil";
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import MeasurementConverter from "utils/MeasurementConverter";
import MeasurementFormatter, { getFormulaVariables, SURFACE_FROM_TASKS_FUNCTION, WEIGHT_FROM_TASKS_FUNCTION } from 'utils/MeasurementFormatter';
import { MEASUREMENT_TREENODE_SEPARATOR } from 'utils/MeasurementUtil';
import { formatCurrency, formatPercentage } from 'utils/NumberFormatter';
import { formatUnit } from 'utils/UnitFormatter';
import { floorRounded, getSafe, isRoundedInteger } from "utils/Utils";
import i18n from 'utils/i18n';
import * as uuidv4 from 'uuid/v4';
import Adjustment from './Adjustment';
import Fee from './Fee';
import MeasurementValue from './MeasurementValue';
import ModelBaseWithCategory from './ModelBaseWithCategories';
import ProvidingItem from "./ProvidingItem";
import TreeNode from './TreeNode';
;

export enum TaskSubtypes {
  Default = 'Default',
  Separator = 'Separator',
}

export default class Task extends ModelBaseWithCategory {
  @serializable @observable type = 'Task';
  @serializable @observable subtype = TaskSubtypes.Default;

  @serializable @observable isMerged = false;

  mergedOriginalTasksIds = new Set<string/*task id*/>();

  @serializable(object(Adjustment)) @observable adjustment: Adjustment = new Adjustment(this.stores);

  // these shortcuts allow tu use handleChange util better (but util could be improved instead)
  @computed get adjustmentValue(): number {
    return this.adjustment.value;
  };

  set adjustmentValue(value: number) {
    this.adjustment.value = value;
  }

  @computed get adjustmentType(): AdjustmentType {
    return this.adjustment.subtype;
  };

  set adjustmentType(type: AdjustmentType) {
    this.adjustment.subtype = type;
  }

  // should only be used when merging tasks in reports
  @observable _quantityOverride = null;
  @observable _nameOverride = null;
  // Providing item price override (unit price), not task price override!
  @observable _priceOverride = null;

  @observable tempMeasurement: Measurement;

  @observable @serializable measurementId: ModelId = ''

  @computed get measurement(): Measurement {
    return this.tempMeasurement || getSafe(() => this.stores.measurementsStore.getItem(this.measurementId)) || null;
  }

  set measurement(value: Measurement) {
    this.measurementId = getSafe(() => value.id) || '';
  }

  @observable @serializable providingItemId: ModelId = ''

  @computed get providingItem(): ProvidingItem {
    return getSafe(() => this.stores.providingItemsStore.getItem(this.providingItemId)) || null;
  }

  set providingItem(value: ProvidingItem) {
    this.providingItemId = getSafe(() => value.id) || '';
  }

  @computed get providingItemDisplayName(): string {
    if (this._nameOverride) {
      return this._nameOverride;
    }

    if (!this.providingItem) {
      return '';
    }

    if (this.providingItem.isDynamicName) {
      let name = this.providingItem.name;
      this.dynamicNameMeasurementValues.forEach((mv) => {
        name = name.replace('{' + mv.measurement.name + '}', MeasurementFormatter.format(mv));
      });

      name = name.replaceAll('{}', '').trim();

      return name;
    } else {
      return this.providingItem.name;
    }
  }

  @computed get dynamicNameMeasurementValues(): MeasurementValue[] {
    if (!this.providingItem || !this.providingItem.isDynamicName) {
      return [];
    }

    const variableNames = getFormulaVariables(this.providingItem.name, '{', '}');
    return compact(
      variableNames.map(variableName => (
        this.treeNode?.measurementValuesArray.find(measurementValue => variableName === measurementValue?.measurement?.name)
      )));
  }

  @computed get hours() {
    const timeProvidedQuantity = this.providingItem?.providedQuantities?.get(UnitType.Time);
    if (this.providingItem?.isLabour && timeProvidedQuantity?.quantity) {
      let value = this.roundedQuantity * timeProvidedQuantity.quantity;
      if (!Number.isFinite(value)) {
        value = 0;
      }
      return MeasurementConverter.convert(value, timeProvidedQuantity.unit, Unit.Hour);
    }

    return 0;
  }

  @computed get timeString() {
    const timeProvidedQuantity = this.providingItem?.providedQuantities?.get(UnitType.Time);
    if (this.providingItem?.isLabour && this.displayProvidedQuantity.unitType !== UnitType.Time && timeProvidedQuantity?.quantity) {
      let value = this.roundedQuantity * timeProvidedQuantity.quantity;
      if (!Number.isFinite(value)) {
        return '';
      }

      return ` (${round(value, 2)} ${formatUnit(timeProvidedQuantity.unit)}) `;
    }

    return '';
  }

  getSecondaryText(isReportView?: boolean) {
    if (!this.measurement || this.measurement.unitType === UnitType.FixedPrice) {
      return '';
    }

    const { treeNodesStore } = this.stores;

    // in report merged task, not sure if we can find the right treenode
    const measurementValue = treeNodesStore.getNodeForTask(this)?.measurementValues?.get(this.measurement.id);
    let adjustmentText = '';

    if (measurementValue) {
      if (this.adjustmentType === AdjustmentType.Multiply && this.adjustmentValue !== 100) {
        if (this.adjustmentValue > 0 && this.adjustmentValue % 100 === 0) {
          adjustmentText += ' x ' + this.adjustmentValue / 100
        } else {
          adjustmentText += ' @ ' + this.adjustmentValue + ' %'
        }
      }
      if (
        measurementValue.adjustmentValue ||
        this?.adjustmentValue && this?.adjustmentType !== AdjustmentType.Multiply
      ) {
        adjustmentText += ' (' + i18n.t('after adjustments') + ') ';
      }
    }

    const { displayQuantity, unit, unitPriceWithOrWithoutFees, quantity, calculationProvidedQuantity } = this;

    const displayQuantityString = (displayQuantity || 0).toLocaleString(i18n.locale, {
      maximumFractionDigits: 2 // should use same logic as in MeasurementFormatter
    });

    const quantityString = (quantity * calculationProvidedQuantity.quantity).toLocaleString(i18n.locale, {
      maximumFractionDigits: 2 // should use same logic as in MeasurementFormatter
    });

    if (isReportView) {
      const valueText = this.displayProvidedQuantity !== this.calculationProvidedQuantity
        // would be nice to display original measurement on top of quantity, but not working until we merge
        // measurementValue on top of quantityOverride
        //? ' (' + MeasurementFormatter.format(measurementValue, this?.adjustment) + ') '
        ? ''
        : '';

      const measurementName = this.measurement.name + valueText;

      return this.measurement.isOneTimeUse
        ? ''
        : measurementName;
    }


    // todo allow waste to be by task, and waste can be on unit when not manually entered
    return (
      displayQuantityString + ' ' +
      formatUnit(unit) +
      ' x ' +
      formatCurrency(unitPriceWithOrWithoutFees) +
      ' / ' +
      formatUnit(unit) +
      this.timeString +
      (
        this.shouldApplyWaste
          ? ' ' + i18n.t('(incl. {{ formattedWastePercentage }} waste)', { formattedWastePercentage: formatPercentage(this.providingItem.wastePercent) })
          : ''
      )
    );
  }

  @computed get secondaryText() {
    return this.getSecondaryText();
  }

  @computed get secondaryTextWithMeasurementName() {
    return this.getSecondaryText(true);
  }

  // to be used when task is separator
  @observable @serializable description: string; // should use name instead

  @computed get isSeparator(): boolean {
    return this.subtype === TaskSubtypes.Separator;
  }

  // providedQuantity used to calculate rounding
  // e.g. provides 10 sq.ft, calculation will be rounded to 10 sq.ft (accross the project)
  @computed get calculationProvidedQuantity(): ProvidedQuantity {
    const measurementUnitType = this.measurement?.unitType;

    let providedQuantity = getSafe(() => this.providingItem.providedQuantities.get(measurementUnitType));

    // allow exception for badly configured item (possibly batch imported) to use unit as hour
    if (
      !providedQuantity &&
      this.providingItem?.providedQuantities?.size == 1 &&
      measurementUnitType === UnitType.Time
    ) {
      providedQuantity = getSafe(() => this.providingItem.providedQuantities.get(UnitType.Unit));
    }

    // ignore badly configured quantity
    if (providedQuantity?.quantity === 0) {
      providedQuantity = null;
    }

    return providedQuantity || (
      (measurementUnitType === UnitType.Pack || measurementUnitType === UnitType.Unit || measurementUnitType === UnitType.FixedPrice)
        // item implicitly provides 1 Pack
        ? new ProvidedQuantity(measurementUnitType, 1, this.measurement.displayUnit, 0)
        // unknown so returning infinity which will result in 0$ task
        : new ProvidedQuantity(measurementUnitType, Number.POSITIVE_INFINITY, Unit.DefaultMetric, 0)
    );
  }

  // providedQuantity used to display
  // Eg. could be 1 truck, or number of hours, even if calculationProvidedQuantity is in Cu. ft.
  @computed get displayProvidedQuantity(): ProvidedQuantity {
    const measurementUnitType = getSafe(() => this.measurement.unitType);

    if (!this.providingItem) {
      return new ProvidedQuantity(UnitType.Unit, 1, Unit.Unit, 0);
    }

    // for labour & fees, look for specified unit like Truck first, unless
    const unitQuantity = this.providingItem?.providedQuantities.get(UnitType.Unit);
    if (this.providingItem.isLabour && unitQuantity && measurementUnitType !== UnitType.Time) {
      return unitQuantity;
    }

    // same for pack
    const packQuantity = this.providingItem?.providedQuantities.get(UnitType.Pack);
    if (this.providingItem.isLabour && packQuantity && measurementUnitType !== UnitType.Time) {
      return packQuantity;
    }

    // no pack or unit conversion logic for labour items
    // or when price is per 1 sq.ft.
    return (
      this.providingItem.isLabour ||
      this.calculationProvidedQuantity.quantity == 1 || // not triple equal because of compatiblity problems
      !this.providingItem.canBeRounded
    ) ? this.calculationProvidedQuantity
      : (
        getSafe(() => this.providingItem.providedQuantities.get(UnitType.Unit).quantity > 1) ||
        getSafe(() => this.providingItem.providedQuantities.get(UnitType.Pack))
      ) ? (
        // Pack: price is for 10 units (can't buy less than 10 units)
        getSafe(() => this.providingItem.providedQuantities.get(UnitType.Pack)) ||
        new ProvidedQuantity(UnitType.Pack, 1, Unit.Pack, 0)
      ) : (
        // Price is for 10 feet (ex. 2x8x10) can't buy less than 10 ft
        // try to use display unit of type unit, or else just the word "unit"
        getSafe(() => this.providingItem.providedQuantities.get(UnitType.Unit)) ||
        Array.from(this.providingItem.providedQuantities.values()).find(providedQuantity => providedQuantity.quantity == 1) ||
        new ProvidedQuantity(UnitType.Unit, 1, Unit.Unit, 0)
      );
  }

  @computed get unit(): Unit {
    return getSafe(() => this.displayProvidedQuantity.unit) || Unit.Unit;
  }

  @computed get treeNode(): TreeNode {
    const { treeNodesStore } = this.stores;

    return treeNodesStore.getNodeForTask(this);
  }

  @computed get shouldApplyWaste() {
    const { userInfoStore, settingsStore } = this.stores;
    const shouldAllowWasteForLabour = userInfoStore.user?.shouldAllowWasteForLabour || settingsStore?.settings?.shouldAllowWasteForLabour;

    return (
      this.measurement.shouldApplyWaste &&
      this.providingItem.wastePercent &&
      (!this.providingItem.isLabour || shouldAllowWasteForLabour) //&&
      //removing this line because: 1.5 cu. yd. of top soil from a calculated measurement should apply waste
      //this.calculationProvidedQuantity.quantity != 1 // double equal because sometimes string!
    );
  }

  // quantity applied with calculationProvidedQuantity
  @computed get quantity(): number {
    const { selectedProject } = this.stores.projectsStore;

    if (this._quantityOverride) {
      return this._quantityOverride;
    }

    if (!this.providingItem || !this.measurement) {
      return 0;
    }

    if (!this.treeNode) {
      return 0;
    }

    const measurementValue = this.treeNode.measurementValues.get(this.measurement.id);

    if (!measurementValue) {
      return 0;
    }

    let metricValue = measurementValue.metricValue || 0; // change NaN to 0

    if (this.adjustment.value) {
      const metricAdjustment = MeasurementConverter.convert(this.adjustmentValue, this.measurement.displayUnit, Unit.DefaultMetric);


      const adjustmentMultiplier = (
        measurementValue.treeNode?.quantitiesMultiplier !== 1 &&
        measurementValue.measurement?.isSummable &&
        metricAdjustment &&
        (this.adjustmentType === AdjustmentType.Add || this.adjustmentType === AdjustmentType.Subtract)
      ) ? (measurementValue.treeNode?.quantitiesMultiplier || 1)
        : 1;

      switch (this.adjustmentType) {
        case AdjustmentType.Add:
          metricValue += metricAdjustment * adjustmentMultiplier;
          break;
        case AdjustmentType.Subtract:
          metricValue -= metricAdjustment * adjustmentMultiplier;
          break;
        case AdjustmentType.Multiply:
          metricValue *= this.adjustmentValue / 100;
          break;
      }
    }

    const retval = round((
      metricValue /
      MeasurementConverter.convert(this.calculationProvidedQuantity.quantity, this.calculationProvidedQuantity.unit, Unit.DefaultMetric)
    ),
      // patches rounding error that looks like 12.000000000002
      // but don't affect old projects calculation
      selectedProject?.version > 0
        ? 10
        : 999
    );

    // when added directly inline, assume it,s the correct quantity, but might not be the correct assumption
    const wasteFactor = this.shouldApplyWaste
      ? 1 + this.providingItem.wastePercent / 100
      : 1;

    // todo add markup

    // 400*1.1 = 440.00000000000006 (?)
    return round(retval * wasteFactor, 12);
  }

  @computed get shouldRound(): boolean {
    // dont round when / sq.ft or
    // (assumption: you can buy 1.5 sq ft of something)
    // maybe should be option inside providingitem
    return (
      this.providingItem?.canBeRounded &&
      // special case of total formulas that cannot be themselves rounded yet because already complicated not to run into cycles
      ![SURFACE_FROM_TASKS_FUNCTION, WEIGHT_FROM_TASKS_FUNCTION].some(functionName => getSafe(() => this.treeNode.measurementValues.get(this.measurementId).formula.toLowerCase().includes(functionName.toLowerCase()))) &&
      //removing this line because: 1.5 cu. yd. of top soil should round to 2 cu. yd.
      //this.calculationProvidedQuantity.quantity != 1 &&// double equal because sometimes string!
      // exclude integer quantities which don't make sens to increase
      (!isRoundedInteger(this.quantity) || this.minimumQuantity) &&
      (!this.measurement || this.measurement.unitType !== UnitType.FixedPrice)
      //&&
      // removed because when putting manual quantity 40 sq ft with can of paint that provides 500 sq ft, was giving weird 0.04 un.
      // doesn't seem a good reason not to round, but maybe there are other cases when it makes sense
      //!this.measurement.isOneTimeUse
    );
  }

  // minimum quantity for all tasks with same item or only current task
  // depends on rounding method
  @computed get minimumQuantity() {
    return this.providingItemPrice ? this.providingItem.minimumPrice / this.providingItemPrice : 0;
  }

  // rounded quantity applied with calculationProvidedQuantity
  @computed get roundedQuantity(): number {
    const { selectedProject } = this.stores.projectsStore;

    if (this._quantityOverride) {
      return this._quantityOverride;
    }

    if (!this.quantity && (!this.providingItem || !this.measurement)) {
      return 0;
    }

    if (!this.shouldRound) {
      // for packs that are sold incomplete, should still round to unit of pack
      // ex. round to 1 nail, for a box of nail sold by the nail, not by box
      if (
        this.displayProvidedQuantity.unitType === UnitType.Unit &&
        this.displayProvidedQuantity.quantity > 1 &&
        selectedProject?.versionOnCreation >= 4
      ) {
        return round(this.quantity * this.calculationProvidedQuantity.quantity, 5) / this.calculationProvidedQuantity.quantity;
      }

      return this.quantity;
    }

    // Rare edge case: item is 1$ for 0.0125h instead of 80$ for 1h, round to hours, not to blocks of 0.0125h
    if (
      this.providingItem?.isLabour &&
      this.displayProvidedQuantity.unitType === UnitType.Time &&
      this.displayProvidedQuantity.quantity !== floorRounded(this.displayProvidedQuantity.quantity)
    ) {
      return Math.round(this.quantity * this.displayProvidedQuantity.quantity) / this.displayProvidedQuantity.quantity;
    }

    if (this.providingItem?.roundingMethod !== RoundingMethod.RoundByTask) {
      // check to reuse an providing item that was not completely used up in other tasks
      // e.g. maybe a box of nails can be shared between this task and another one in the project
      const { tasksStore } = this.stores;
      const tasksWithSameProvidingItem = this.providingItem
        ? (tasksStore.roundableTasksByProvidingItem[this.providingItem.id] || [])
        : [];

      if (tasksWithSameProvidingItem.length > 1) {
        // what if using different units?!! e.g. ft and in.?
        // shouldn't happen because we don't round items that can be bought by length e.g. price per ft.
        // and for others the quantity will be the same because it's just a multiple of providedQuantity
        const totalItemQuantity = sumBy(tasksWithSameProvidingItem, 'quantity');
        let roundedItemQuantity = Math.max(this.minimumQuantity, Math.ceil(totalItemQuantity))

        const ownPartOfItem = this.quantity / totalItemQuantity;
        const roundedOwnPartOfItem = ownPartOfItem * roundedItemQuantity;

        return roundedOwnPartOfItem;
      }
    }

    return Math.max(this.minimumQuantity, Math.ceil(this.quantity));
  }

  @computed get displayQuantity(): number {
    const { selectedProject } = this.stores.projectsStore;

    // round quantities to 2 digits now
    return (selectedProject?.versionOnCreation >= 4 ? round : identity)(
      this.roundedQuantity * this.displayProvidedQuantity.quantity,
      2
    );
  }

  // SHOULD ALWAYS USE these getters, not this.providingItem.price directly
  @computed get providingItemPrice(): number {
    if (this._priceOverride) {
      return this._priceOverride;
    }

    if (!this.providingItem) {
      return 0;
    }

    if (!this.providingItem.priceFormula) {
      return this.providingItem.priceWithPriceAdjustment;
    }

    // only supports formula that looks exactly like 'Measurement Name' * 00.00
    // should eventually use the real parser like MeasurementValue
    const floatPart = parseFloat(this.providingItem.priceFormula.replace(/[^0-9\.]/g, ''));

    return this.dynamicPriceMeasurementValue
      ? MeasurementFormatter.getValueWithoutUnit(this.dynamicPriceMeasurementValue) * floatPart
      : this.providingItem.priceWithPriceAdjustment;
  }

  @computed get dynamicPriceMeasurementValue(): MeasurementValue {
    const {treeNodesStore} = this.stores;
    if (!this.providingItem || !this.providingItem.priceFormula) {
      return null;
    }

    // only supports formula that looks exactly like 'Measurement Name' * 00.00
    // should eventually use the real parser like MeasurementValue
    let variableName = getSafe(() => getFormulaVariables(this.providingItem.priceFormula)[0]);

    if (!variableName) {
      return null;
    }

    let treeNode = this.treeNode;

    if (variableName.includes(MEASUREMENT_TREENODE_SEPARATOR)) {
      const variableNameTokens = variableName.split(MEASUREMENT_TREENODE_SEPARATOR);
      const treeNodeName = variableNameTokens[0];
      variableName = variableNameTokens[1];

      treeNode = treeNodesStore.getItemByName(treeNodeName) || treeNode;
    }

    const variableMeasurementValue = treeNode?.measurementValuesArray
      .find(measurementValue => variableName === measurementValue?.measurement?.name);

    return variableMeasurementValue;
  }

  @computed get providingItemPriceWithFees(): number {
    if (!this.providingItem) {
      return 0;
    }

    return getPriceWithFees(this.providingItemPrice, this.providingItem);
  }

  getUnitPrice = computedFn((withFees) => {
    const price = withFees ? this.providingItemPriceWithFees : this.providingItemPrice;

    // should disable this weird logic, but make sure to test on 
    // https://app.evalumo.com/ca/projects/test-lp-evalumo-_-const-steve-lussier-job-entrepot-forget-1/report?user=s.marion@estimationsml.com
    // or somewhere nails no rounding and mixed with fixed price

    // here we calculate what the task price should be, but to get rounding right, we need to calculate
    // task price again, once we find out unit price..
    // E.g.: initialTaskPrice = 1.00$, quantity = 60 -> unitPrice =  0.02 $ -> final task price = 1.20 $
    // Also minimum unit price is 0.01$ unless price hasn't been set at all for item
    let roundedQuantity = this.roundedQuantity;
    let displayQuantity = this.displayQuantity;
    if (this.roundedQuantity === 0) {
      // can't reverse calculate unit price when quantity is 0 because total price is 0
      // in that case, simulate a quantity of 1
      roundedQuantity = 1 / this.displayProvidedQuantity.quantity;
      displayQuantity = 1;
    }

    const initialTaskPrice = getSafe(() => roundPrice(roundedQuantity * price)) || 0;
    return roundPrice(getSafe(() => initialTaskPrice / displayQuantity || price) || 0) ||
      (price ? 0.01 : 0);
  })

  @computed get unitPrice(): number {
    return this.getUnitPrice(false);
  }

  @computed get unitPriceWithFees(): number {
    const { selectedProject } = this.stores.projectsStore;

    return (selectedProject?.versionOnCreation >= 5)
      ? getPriceWithFees(this.unitPrice, this.providingItem)
      : this.getUnitPrice(true);
  }

  @computed get unitPriceWithOrWithoutFees(): number {
    const { commonStore } = this.stores;
    return commonStore.shouldIncludeFeesInTasks ? this.unitPriceWithFees : this.unitPrice;
  }

  // without fees
  @computed get price(): number {
    if (this.measurement?.unitType === UnitType.FixedPrice) {
      if (this._quantityOverride) {
        return roundPrice(this._quantityOverride);
      }

      const measurementValue = this.treeNode?.measurementValues?.get(this.measurement.id);

      if (!measurementValue) {
        return 0;
      }

      return roundPrice(measurementValue.metricValue);
    }

    return getSafe(() => roundPrice(this.displayQuantity * this.unitPrice)) || 0;
  }

  @computed get providingItemFeesValues(): Map<Fee, number> {
    const { settingsStore } = this.stores;

    if (!this.providingItem) {
      return new Map();
    }
    return this.providingItem.getProvidingItemFeesValues(this.unitPrice);
  }

  @computed get priceWithOrWithoutFees(): number {
    const { commonStore } = this.stores;
    return commonStore.shouldIncludeFeesInTasks
      ? this.priceWithFees
      : this.price;
  }

  @computed get feesValues(): Map<Fee, number> {
    const { settingsStore } = this.stores;
    if (this.measurement?.unitType === UnitType.FixedPrice) {
      return this.providingItem.getProvidingItemFeesValues(this.price);
    }

    // multiply unit fee by quantity
    const retval = new Map(this.providingItemFeesValues);
    Array.from(retval.keys()).forEach(fee => {
      retval.set(fee, roundPrice(this.displayQuantity * retval.get(fee)));
    });

    // has to be a very small difference, not sure needed
    const roundingDifference = this.priceWithFees - sumPrices([this.price, ...Array.from(retval.values())]);

    if (retval.size) {
      const firstKey = retval.keys().next().value;
      retval.set(firstKey, sumPrices([retval.get(firstKey), roundingDifference]))
    }

    if (
      settingsStore.settings?.areFeesIncludedInItemPrice &&
      round(this.priceWithFees - sumPrices([this.price, ...Array.from(retval.values())]), 4) !== 0
    ) {
      debugger;
    }

    return retval;
  }

  @computed get priceWithFees(): number {
    const { settingsStore } = this.stores;
    if (this.measurement?.unitType === UnitType.FixedPrice) {
      return getPriceWithFees(this.price, this.providingItem);
    }

    return getSafe(() => roundPrice(this.displayQuantity * this.unitPriceWithFees)) || 0;
  }

  cloneDeep(
    batchParam: WriteBatch,
    targetStores = this.stores,
    quantityMeasurementsCopyMap = new Map<ModelId, ModelId>(),
    skipMeasurements = false,
    skipProvidingItems = false,
    shouldKeepSameTasksIds = false,
  ): this {
    const { userInfoStore } = this.stores;
    const { nonImpersonatedUser } = userInfoStore;

    const taskCopy = super.clone()
    if (!shouldKeepSameTasksIds) {
      taskCopy.id = uuidv4();
    }

    if (
      this.measurement &&
      !skipMeasurements && (
        this.measurement.isOneTimeUse ||
        // no usecase to overwrite an existing measurement in target store, only copy if doesn't exist
        !targetStores.measurementsStore.getItem(this.measurement.id)
      )
    ) {
      if (quantityMeasurementsCopyMap.has(this.measurementId)) {
        taskCopy.measurementId = quantityMeasurementsCopyMap.get(this.measurementId);
      } else {
        const measurementCopy = this.measurement.clone(
          targetStores,
          this.measurement.isOneTimeUse ? uuidv4() : this.measurementId
        )

        if (measurementCopy.id !== this.measurement.id) {
          // keep track of measurement new id, to update treeNode measurement reference
          quantityMeasurementsCopyMap.set(this.measurementId, measurementCopy.id);
        }

        targetStores.measurementsStore.batchAddEditItem(measurementCopy, batchParam);
        taskCopy.measurement = measurementCopy;
      }
    }

    if (this.providingItem && !skipProvidingItems) {
      const providingItemCopy = this.providingItem.clone();
      if (this.providingItem.priceFormula !== providingItemCopy.priceFormula) {
        // trying to find a bug that sets prices to zero by mistake
        throw new Error('Erreur importante lors du clonage, svp nous faire part de ce message.');
      }

      if (!nonImpersonatedUser.shouldAutoSyncProvidingItems) {
        targetStores.providingItemsStore.saveToDb(
          [providingItemCopy],
          targetStores.providingItemsStore.collections.filter(collection => collection === targetStores.providingItemsStore.projectCollection),
          undefined,
          batchParam
        );
      } else {
        targetStores.providingItemsStore.batchAddEditItem(providingItemCopy, batchParam);
      }

      taskCopy.providingItem = providingItemCopy;
    }

    return taskCopy;
  }
}
