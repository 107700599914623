import { Button, ClickAwayListener, FilledInput, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import AcceptIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';
import CountIcon from '@material-ui/icons/LocationOn';
import LockIcon from '@material-ui/icons/Lock';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import WarningIcon from '@material-ui/icons/Warning';
import Globals from 'Globals';
import classnames from 'classnames';
import IntegerField from 'components/common/IntegerField/IntegerField';
import { AdjustmentType } from 'constants/AdjustmentType';
import { DrawToolType } from 'constants/DrawToolType';
import { FormulaType } from 'constants/FormulaType';
import { MeasurementType } from 'constants/MeasurementType';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { compact, isEmpty, noop, omit } from 'lodash';
import { action, computed } from 'mobx';
import Adjustment from 'models/Adjustment';
import Dialog from 'models/Dialog';
import DrawToolsOption from 'models/DrawToolsOption';
import Line from 'models/Line';
import Measurement from 'models/Measurement';
import MeasurementValue from 'models/MeasurementValue';
import Shape from 'models/Shape';
import Surface from 'models/Surface';
import Task from 'models/Task';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import ReactDOM from 'react-dom';
import sanitizeHtml from 'sanitize-html';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import MeasurementConverter from 'utils/MeasurementConverter';
import MeasurementFormatter, { formulaHasVariables, formulaIsNumber } from 'utils/MeasurementFormatter';
import { getMeasuremementCustomFormatter, getMeasuremementCustomIconFunction, getMeasurementCustomSelectOptions, measurementHasDefaultName, onMeasurementDrop, shouldUseAsDefaultFormula as shouldUseAsDefaultFormulaFunction, showMeasurementDialog } from 'utils/MeasurementUtil';
import { InputNumberFormatDecimal, InputNumberFormatInteger } from 'utils/NumberFormatter';
import { getUnitTypeForUnit } from 'utils/UnitFormatter';
import { areModelsDifferent, round } from 'utils/Utils';
import i18n from 'utils/i18n';
import { Avatar } from '../Avatar/Avatar';
import ColorButton from '../ColorButton/ColorButton';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import DroppableDiv from '../DroppableDiv/DroppableDiv';
import FormulaTypeIcon from '../FormulaTypeIcon/FormulaTypeIcon';
import { SumIcon } from '../Icons';
import { ListItemAvatar } from '../ListItemAvatar/ListItemAvatar';
import { ListItemText } from '../ListItemText/ListItemText';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';
import { Typography } from '../Typography/Typography';
import UnitComboBox from '../UnitComboBox/UnitComboBox';

const colors = require('../../../Colors.scss');
const styles = require('./MeasurementComponent.module.scss');

interface IMeasurementComponentProps extends React.HTMLProps<HTMLDivElement> {
  measurement: Measurement,
  measurementValue: MeasurementValue,
  treeNode?: TreeNode,
  drawToolsOption?: DrawToolsOption,
  // html props... is there a better way?
  isEditable?: boolean,
  // when editable, sometimes we can only change the measurement value not measurement properties
  isValueEditableOnly?: boolean,
  task?: Task,
  shouldHideValue?: boolean,
}

interface IMeasurementComponentState {
  measurementCopy: Measurement,
  measurementValueCopy: MeasurementValue,
  treeNodeCopy: TreeNode,
  shouldShowFormula: boolean,
  shapes: Shape[],
}

export default class MeasurementComponent extends ObserverComponent<IMeasurementComponentProps, IMeasurementComponentState> {
  state = {
    ...super.state,
    isValueEditableOnly: false,
    measurementCopy: null,
    measurementValueCopy: null,
    treeNodeCopy: null,
    shouldShowFormula: false,
    shapes: [],
  }

  rootEditRef;

  getState = () => {
    const { measurement, measurementValue, treeNode } = this.props;

    const measurementCopy = measurement.clone();
    const measurementValueCopy = measurementValue.clone();
    const treeNodeCopy = treeNode.clone() || new TreeNode(this.context);

    measurementValueCopy.tempMeasurement = measurementCopy;
    measurementValueCopy.tempTreeNode = treeNodeCopy;

    return {
      measurementCopy,
      measurementValueCopy,
      treeNodeCopy,
    };
  }

  handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // hack, somehow dispatches when closing color button dialog by clicking anywhere
    const node = ReactDOM.findDOMNode(this);
    if (e.target !== node && !node?.contains?.(e.target)) {
      return;
    }

    const { isEditable, treeNode, measurement, task } = this.props;
    const { treeNodesStore } = this.context;

    const measurementValue = treeNode.measurementValues.get(measurement.id);

    if (isEditable) {
      if (!measurementValue) {
        treeNodesStore.toggleNodeMeasurements([measurement], true, treeNode, true);
      }

      this.enterEditMode();
    } else {
      showMeasurementDialog(measurement, treeNode, task);
    }
  }

  @computed get isEditMode() {
    const { measurementsStore, tasksStore, } = this.context;
    return (
      this.props.isEditable &&
      measurementsStore.measurementBeingEdited?.id === this.props.measurement?.id &&
      tasksStore.taskBeingEdited === this.props.task
    );
  }

  enterEditMode = () => {
    const { dialogsStore, tasksStore, measurementsStore } = this.context;
    const { isEditable, task, measurement } = this.props;

    // don't show when another measurement dialog is already open
    if (dialogsStore?.topMostDialog?.subtype === 'MeasurementEditDialog') {
      return;
    }

    tasksStore.taskBeingEdited = task;
    measurementsStore.measurementBeingEdited = measurement;

    this.setState({ measurementCopy: null, measurementValueCopy: null, treeNodeCopy: null });
  }

  exitEditMode = () => {
    const { tasksStore, measurementsStore } = this.context;
    const { task } = this.props;
    if (task) {
      tasksStore.taskBeingEdited = null;
    }

    measurementsStore.measurementBeingEdited = null;

    this.setState({ measurementCopy: null, measurementValueCopy: null, treeNodeCopy: null });
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { tasksStore, measurementsStore } = this.context;

    if (this.isEditMode) {
      tasksStore.taskBeingEdited = null;
      measurementsStore.measurementBeingEdited = null;
    }
  }

  @action
  saveChanges = () => {
    if (document.activeElement?.role === 'combobox') {
      //cobrowse messes up with the focus
      return;
    }

    // DUPLICATE WITH Measurement edit dialog!
    const { measurementCopy, measurementValueCopy, treeNodeCopy } = this.state;
    const { treeNode, drawToolsOption, measurement: originalMeasurement } = this.props;
    const { measurementsStore, treeNodesStore } = this.context;

    if (
      measurementCopy.isOneTimeUse &&
      !measurementHasDefaultName(measurementCopy) &&
      measurementCopy.unitType !== UnitType.FixedPrice
    ) {
      measurementCopy.isOneTimeUse = false;
    }

    const shouldUseAsDefaultFormula = shouldUseAsDefaultFormulaFunction(
      false,
      measurementValueCopy.formula,
      measurementCopy.defaultFormula,
      measurementCopy.unitType !== UnitType.Projection && measurementCopy.unitType !== UnitType.Angle
    )
    if (shouldUseAsDefaultFormula) {
      measurementCopy.defaultFormula = measurementValueCopy.formula;
    }

    // test whether @computed will get recomputed if inside transaction
    const batch = firestoreBatch(this.context);

    if (!measurementCopy.isReadonly) {
      measurementsStore.batchAddEditItem(measurementCopy, batch);
    } else if (shouldUseAsDefaultFormula && formulaIsNumber(measurementCopy.defaultFormula)) {
      measurementsStore.addEditItem(measurementCopy, true, ['__defaultFormula'], batch);
    }
    measurementValueCopy.measurement = measurementCopy;

    // useless to do here! we can't set adjustments from inline edit!
    const adjustment = treeNodeCopy.ownMeasurementAdjustments.get(measurementCopy.id);
    if (adjustment) {
      adjustment.measurementId = measurementCopy.id; // annoying that this isn't done automatically by serializr
      treeNode.ownMeasurementAdjustments.set(
        measurementCopy.id,
        adjustment,
      );
    }

    measurementValueCopy.tempTreeNode = null;

    treeNodesStore.applyMeasurementValues(treeNode, [measurementValueCopy], false, batch);
    treeNodesStore.addEditItem(treeNode, true, ['ownMeasurementAdjustments'], batch);

    // when setting to null, we also lose the temporary measurement formula set in edit mode,
    // so make sure this happens after applying it to treeNode
    measurementValueCopy.tempMeasurement = null;

    if (drawToolsOption) {
      drawToolsOption.formula = measurementValueCopy;
    }

    batch.commit();

    this.exitEditMode();
  }

  handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { measurementCopy } = this.state; // todo: separate edit mode/dialog better
    let { value } = event.target as any;

    measurementCopy.name = value;
  }

  handleChangeQuantityFromSelect = ({ target: { value } }) => {
    const { measurementCopy, measurementValueCopy } = this.state;
    measurementValueCopy.formula = '' + MeasurementConverter.convert(value, Unit.DefaultMetric, measurementCopy.displayUnit);
  }

  handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { measurementValueCopy } = this.state; // todo: separate edit mode/dialog better
    const { value, formattedValue } = event.target;

    measurementValueCopy.formula = (formattedValue === '.' || formattedValue.startsWith('. '))
      ? '.'
      : (value || 0).toString();
  }

  handleChangeUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { measurementCopy, measurementValueCopy } = this.state as IMeasurementComponentState; // todo: separate edit mode/dialog better
    const newUnit = event.target.value as Unit;

    // for convenience try to guess if user wants to apply waste
    if (
      measurementCopy.displayUnit === Unit.Unit &&
      measurementCopy.isOneTimeUse && (
        getUnitTypeForUnit(newUnit) === UnitType.Surface ||
        getUnitTypeForUnit(newUnit) === UnitType.Length
      )) {
      measurementCopy._shouldApplyWaste = true;
    } else if (
      measurementCopy.isOneTimeUse && (
        measurementCopy.unitType === UnitType.Surface ||
        measurementCopy.unitType === UnitType.Length
      ) && newUnit == Unit.Unit) {
      measurementCopy._shouldApplyWaste = false;
    }

    measurementCopy.unitType = getUnitTypeForUnit(newUnit);

    measurementCopy.displayUnit = newUnit;
  }

  // duplicate
  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.saveChanges();
    } else if (e.key === 'Escape') {
      this.exitEditMode();
    }
  }

  startCounting = (e) => {
    const { drawToolsStore } = this.context;
    const { measurement } = this.props;

    drawToolsStore.selectedTool = DrawToolType.GeneralCount;

    drawToolsStore.selectedCountToolMeasurement = measurement;

    this.exitEditMode();
  }

  removeFromTask = () => {
    const { tasksStore, dialogsStore } = this.context;
    const { task } = this.props;

    // if many tasks are checked, allow to remove from all checked tasks
    if (
      tasksStore.selectedItems.has(task) &&
      tasksStore.selectedItems.size > 1
    ) {
      const newDialog = new Dialog(this.context);
      newDialog.dialogComponent = ({ open }) => (
        <ConfirmDialog
          open={open}
          dialogId={newDialog.id}
          onConfirm={() => {
            tasksStore.selectedItems.forEach(task => {
              task.adjustment = new Adjustment(this.context);
              task.measurement = null
            });
            tasksStore.addEditItems(tasksStore.selectedItemsArray, true, ['measurementId', 'adjustment']);
          }}
          onClose={() => {
            if (task.measurement) {
              task.adjustment = new Adjustment(this.context);
              task.measurement = null;

              tasksStore.addEditItem(task, true, ['measurementId', 'adjustment']);
              tasksStore.selectedItems.clear();
            }
          }}
          title={i18n.t('Also remove measurements from other selected tasks ?')}
          noLabel={i18n.t('Remove from this one task only')}
          yesLabel={i18n.t('Remove from all checked tasks')}
        />
      );
      dialogsStore.showDialog(newDialog);
    } else {
      task.adjustment = new Adjustment(this.context);
      task.measurement = null;

      tasksStore.addEditItem(task, true, ['measurementId', 'adjustment']);
    }
  }

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const { measurementCopy, measurementValueCopy } = this.state;
    if (this.isEditMode && this.props.measurement && this.props.measurementValue && !measurementCopy && !measurementValueCopy) {
      return this.setState(this.getState());
    }
  }

  onMouseEnter = () => {
    const { drawToolsStore, shapesStore } = this.context;
    const { measurementValue } = this.props;
    if (!isEmpty(measurementValue.relatedShapesIds)) {
      drawToolsStore.shapesToHighlightFromHoveredMeasurement = new Set(
        compact(measurementValue.relatedShapesIds.map(shapeId => shapesStore.getItem(shapeId)))
      );
    }
  }

  onMouseLeave = () => {
    const { drawToolsStore } = this.context;
    if (!isEmpty(drawToolsStore.shapesToHighlightFromHoveredMeasurement)) {
      drawToolsStore.shapesToHighlightFromHoveredMeasurement = new Set();
    }
  }

  _render() {
    const { measurementsStore, drawToolsStore, dragAndDropStore, userInfoStore, settingsStore } = this.context;
    const { isEditable, className, treeNode, task, drawToolsOption, shouldHideValue } = this.props;
    let { isValueEditableOnly } = this.props;
    const { shouldShowFormula, measurementCopy, measurementValueCopy } = this.state;

    const measurement: Measurement = this.isEditMode ? measurementCopy : this.props.measurement;

    if (this.isEditMode && this.props.measurement && this.props.measurementValue && !measurementCopy && !measurementValueCopy) {
      return null;
    }

    if (!measurement) {
      return null;
    }

    const isDifferentFromUserModel = measurement && areModelsDifferent(measurementsStore.userCollectionItemsMap.get(measurement.id), measurement);
    const isDeletedInUserAccount = (
      measurement.owner !== userInfoStore.nonImpersonatedEmail &&
      measurement.cascadeOrders.size == 1 &&
      measurement.cascadeOrders.has(measurementsStore.collections.indexOf(measurementsStore.projectCollection))
    );

    const { user } = userInfoStore;

    const { unitType, isHidden } = measurement;
    let { name } = measurement;

    if (Object.values(i18n.tAll('Quantity')).includes(name) && measurement.unitType === UnitType.FixedPrice) {
      name = i18n.t('Fixed Price');
    }

    let measurementValue: MeasurementValue = this.isEditMode ? measurementValueCopy : this.props.measurementValue;
    let formula, hasVariables;
    if (measurementValue) {
      formula = measurementValue.formula;
      hasVariables = formulaHasVariables(measurementValue.formula);
    }

    isValueEditableOnly = (isValueEditableOnly || !this.props.measurementValue?.metricValue) &&
      !task?.adjustmentValue &&
      treeNode?.quantitiesMultiplier === 1;

    // probably a more elegant way is possible
    const htmlProps = omit(this.props, ['measurement', 'measurementValue', 'treeNode', 'isEditable', 'isValueEditableOnly', 'task', 'className']);
    let shapes = [
      MeasurementType.CeilingSurface,
      MeasurementType.FloorSurface,
      MeasurementType.WallLength,
      MeasurementType.RoofRealSurface,
      MeasurementType.RoofRealLength,
    ].includes(measurement.measurementType)
      ? treeNode?.shapes || []
      : [];

    shapes = shapes.filter(
      shape => (
        ((shape instanceof Line) && unitType === UnitType.Length) ||
        ((shape instanceof Surface) && unitType === UnitType.Surface)
      )
    );

    const valueWithoutUnit = MeasurementFormatter.getValueWithoutUnit(measurementValue, task?.adjustment);
    const isMeasurementInvalid = (
      valueWithoutUnit === 0 &&
      measurement.unitType !== UnitType.Angle &&
      measurement.unitType !== UnitType.Projection
    );
    const formulaType = measurementValue?.subtype || measurement?.defaultFormulaType || FormulaType.UserParameter;

    const customFormatter = getMeasuremementCustomFormatter(measurement.id as MeasurementType);
    const customIconFunction = getMeasuremementCustomIconFunction(measurement.id as MeasurementType);
    const customSelectOptions = getMeasurementCustomSelectOptions(measurement.id as MeasurementType);

    const isCounting = (
      !task &&
      drawToolsStore.selectedTool === DrawToolType.GeneralCount &&
      treeNode.measurementValuesArray.length > 1 &&
      drawToolsStore.selectedCountToolMeasurement?.id === measurement.id
    );

    const formattedMeasurement = (measurementValue && customFormatter)
      ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            {customIconFunction && customIconFunction(measurementValue.metricValue)}
          </div>
          <div>
            {customFormatter(measurementValue.metricValue)}
          </div>
        </div>
      ) : MeasurementFormatter.format(measurementValue, task?.adjustment);


    const isDrawingContext = Globals.drawingStores === this.context;

    const secondaryText = (
      measurementValue?.adjustmentValue ||
      task?.adjustmentValue && task?.adjustmentType !== AdjustmentType.Multiply
    ) ? '(' + i18n.t('after adjustments') + ')'
      : '';

    return this.isEditMode
      ? (
        <ClickAwayListener mouseEvent={(user.shouldDisableClickAway && this.context.name !== 'defaultStores') ? false : 'onMouseDown'} onClickAway={this.saveChanges}>
          <div ref={ref=> this.rootEditRef = ref} className={classnames(className, styles.rootEditMode, { [styles.hasTask]: !!task })} onKeyDown={this.onKeyPress}>
            {(treeNode && !isValueEditableOnly)
              ? (
                <TextField
                  className={styles.textField + ' ' + styles.nameField + ' ' + styles.textFieldNoLabel}
                  value={name}
                  onChange={this.handleChangeName}
                  disabled={measurement.isReadonly}
                />
              ) : <div className={styles.measurementName}>{name}</div>}

            <div className={styles.quantityAndUnitFields}>
              {(!hasVariables && treeNode.quantitiesMultiplier === 1 && (
                measurement.displayUnit === Unit.Unit ||
                measurement.displayUnit === Unit.Budget ||
                measurement.displayUnit === Unit.Allocation ||
                measurement.displayUnit === Unit.Truck ||
                measurement.displayUnit === Unit.Step
              )) ? (
                <IntegerField
                  shouldFocusOnMount
                  label=""
                  className={styles.textField + ' ' + styles.textFieldNoLabel}
                  value={round(valueWithoutUnit, 2)}
                  onChange={hasVariables ? noop : this.handleChangeQuantity}
                  InputProps={{
                    inputComponent: hasVariables ? undefined : InputNumberFormatInteger,
                  }}
                  //disabled={hasVariables}
                  margin="none"
                />
              ) : customSelectOptions ? (
                <FormControl className={styles.combobox} margin="normal">
                  <InputLabel>{i18n.t(measurement.id)}</InputLabel>
                  <Select
                    value={measurementValue.metricValue}
                    onChange={this.handleChangeQuantityFromSelect}
                    input={<FilledInput />}
                  >
                    {customSelectOptions.map((value, index) => (
                      <MenuItem key={'mk' + index} value={value}><span style={{pointerEvents:'none'}}>{customIconFunction && customIconFunction(value)}&nbsp;{customFormatter(value)}</span></MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  label=""
                  className={classnames(
                    styles.textField,
                    styles.textFieldNoLabel,
                    styles.decimalField,
                    { [styles.hasVariables]: hasVariables }
                  )}
                  // eventually should support formulas inline edit
                  value={(isValueEditableOnly ? valueWithoutUnit : formula) || '0'}
                  onChange={this.handleChangeQuantity}
                  InputProps={{
                    inputComponent: isValueEditableOnly
                      ? measurement.inputFormatter
                      : (hasVariables ? undefined : InputNumberFormatDecimal),
                  }}
                  disabled={!isValueEditableOnly && hasVariables}
                  shouldFocusOnMount
                  shouldSelectAllOnFocus
                  inputProps={{ enterkeyhint: 'done', inputmode: 'decimal' }}
                //placeholder={i18n.t('Enter Quantity')}
                />
              )}

              {!isValueEditableOnly && (
                <UnitComboBox
                  className={styles.select}
                  value={measurement.displayUnit}
                  onChange={this.handleChangeUnit}
                  disabled={hasVariables || measurement.isReadonly}
                  unitTypeOptions={drawToolsOption ? [measurement.unitType] : []}
                />
              )}
            </div>

            <div className={styles.editTools}>
              {task && (
                <Tooltip title={i18n.t('Remove from current task')}>
                  <IconButton className={styles.removeButton} onClick={this.removeFromTask}><RemoveIcon /></IconButton>
                </Tooltip>
              )}

              {/* //nice to have
                task && (
                <Tooltip title={i18n.t('Duplicate')}>
                  <IconButton className={styles.duplicateButton} onClick={this.duplicateMeasurement}><CopyIcon /></IconButton>
                </Tooltip>
                )*/}

              {!task && this.context === Globals.drawingStores && measurement.unitType === UnitType.Unit && (
                <Tooltip title={i18n.t('Count on drawing')}>
                  <IconButton onClick={this.startCounting} className={styles.countButton}>
                    <CountIcon />
                  </IconButton>
                </Tooltip>
              )}

              <div className={styles.flexSeparator} />
              {/* This button could also be used in draw tool mode if we implement it */}
              {treeNode && <Button className={styles.detailsButton} onClick={() => {
                this.exitEditMode();
                showMeasurementDialog(measurement, treeNode, task);
              }}>{i18n.t('Details')}</Button>}
              <div className={styles.detailsSeparator} />

              {(!hasVariables || !measurement.isReadonly) && (
                <IconButton className={styles.cancelButton} onClick={this.exitEditMode}><CancelIcon /></IconButton>
              )}
              <IconButton className={styles.acceptButton} onClick={this.saveChanges}><AcceptIcon /></IconButton>
            </div>
          </div>
        </ClickAwayListener>
      ) : (
        // Not edit mode
        <Tooltip disableTouchListener disableHoverListener={!measurement.description} enterDelay={1800} enterNextDelay={1800} title={measurement.description}>
          <DroppableDiv
            className={classnames(
              styles.root,
              className, {
              [styles.isHidden]: isHidden,
              [styles.hasNoValue]: !measurementValue,
              [styles.isCounting]: isCounting,
              [styles.hasTask]: !!task
            },
              'formulaType' + formulaType
            )}
            onClick={this.handleClick}
            onDrop={onMeasurementDrop(task)}
            onMouseEnter={isDrawingContext ? this.onMouseEnter : noop}
            onMouseLeave={isDrawingContext ? this.onMouseLeave : noop}
            shouldEnable={task && dragAndDropStore.dragObject instanceof Measurement}
            {...htmlProps}
          >
            {!task && (
              <ListItemAvatar className={styles.avatar}>
                <Avatar style={{ width: 30, height: 30 }}>
                  {/*measurement.thumbUrl
                  ? <AvatarImage style={{ height: 30 }} model={measurement} />
                  : */ <FormulaTypeIcon formulaType={formulaType} />
                  }
                </Avatar>
              </ListItemAvatar>
            )}

            {this.context === Globals.drawingStores && !isEmpty(measurementValue?.bubbleDescendantsWithSameMeasurement) && measurement.isSummable && (
              <SumIcon className={styles.sumIcon} />
            )}

            <ListItemText
              className={styles.measurementText}
              primary={
                <div className={styles.measurementAndColor}>
                  <span className={classnames(styles.measurementName, {[styles.hasSecondaryText]: !!secondaryText})}>{
                    (task?.adjustmentValue && task?.adjustmentType === AdjustmentType.Multiply)
                      ? (
                        (task?.adjustmentValue % 100 === 0)
                          ? <span>{name}<span>&nbsp;x&nbsp;{round(task.adjustmentValue / 100, 0)}</span></span>
                          : <span>{name}<span>&nbsp;@&nbsp;{round(task.adjustmentValue, 0)}&nbsp;%</span></span>
                      ) : <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(name.trim()).replace(/ x ([0-9 ]+)$/, '&nbsp;x&nbsp;$1') }} />
                  }
                    {measurement.isSummable && treeNode?.quantitiesMultiplier !== 1 && <span className={styles.multiplierIcon} style={{ fontWeight: 'bold' }} >&nbsp;×{treeNode.quantitiesMultiplier}</span>}
                    {measurementsStore.shouldMarkMasterItems && !task && measurement.cascadeOrders.has(0) && <VerifiedIcon className={styles.verifiedIcon} />}
                    {measurementsStore.shouldMarkReadonlyItems && !task && measurement.isReadonly && <LockIcon className={styles.verifiedIcon} />}
                    {userInfoStore.user?.isAdvancedSharingEnabled && !task && isDifferentFromUserModel && <Tooltip title={i18n.t('This measurement is not up to date with the latest modification.')}><WarningIcon className={styles.verifiedIcon} style={{ color: colors.orange }} /></Tooltip>}
                    {userInfoStore.user?.isAdvancedSharingEnabled && !task && isDeletedInUserAccount && <Tooltip title={i18n.t('This measurement was deleted in the latest modification.')}><WarningIcon className={styles.verifiedIcon} style={{ color: colors.red }} /></Tooltip>}
                  </span>
                  {/* not sure where to put */}
                  {false && this.context === Globals.drawingStores && measurement.unitType === UnitType.Unit && (
                    <ColorButton
                      width={measurement.legendColor ? 13 : 18}
                      height={measurement.legendColor ? 6 : undefined}
                      className={styles.colorButton}
                      color={measurement.legendColor}
                      onChange={color => {
                        measurement.legendColor = color.hex;
                        measurementsStore.addEditItemDebounced(measurement, true, ['legendColor']);
                      }}
                    />
                  )}
                </div>
              }
              secondary={secondaryText}
            />

            {measurementValue && !shouldHideValue && (
              <div className={styles.formulaValue + ' formulaValue'}>
                {/*<FormulaTypeIcon formulaType={formulaType} />*/}
                <Typography variant="subtitle2" style={{ fontWeight: 500, color: isMeasurementInvalid ? colors.red : 'rgba(0,0,0,0.7)' }} className={styles.measurementValue}>{
                  isNaN(measurementValue.metricValue)
                    ? '—'
                    : formattedMeasurement
                }</Typography>
              </div>
            )}
          </DroppableDiv>
        </Tooltip>
      );
  }
}