import ArrowLeftIcon from '@material-ui/icons/KeyboardBackspace';
import Globals from 'Globals';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import MeasurementEditDialog from 'components/common/MeasurementEditDialog/MeasurementEditDialog';
import { AdjustmentType } from 'constants/AdjustmentType';
import { DrawingProjections } from 'constants/DrawingProjections';
import { FormulaType } from 'constants/FormulaType';
import { MeasurementType } from 'constants/MeasurementType';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { compact, filter, first, flatten, flow, isEmpty, sortBy, uniqBy } from 'lodash';
import Dialog from 'models/Dialog';
import Measurement from 'models/Measurement';
import MeasurementValue from 'models/MeasurementValue';
import Task from 'models/Task';
import TreeNode from 'models/TreeNode';
import React from 'react';
import Stores from 'stores/Stores';
import StoresContext from 'stores/StoresContext';
import uuidv4 from 'uuid/v4';
import { WriteBatch } from './FirebaseInitializedApp';
import firestoreBatch from './FirestoreBatchUtil';
import MeasurementConverter from './MeasurementConverter';
import { COUNT_FUNCTION, SAME_AS_CHILDREN_FUNCTION, escapeVar, formulaHasVariables, formulaIsBubbleFunction, formulaIsFromDrawing, formulaIsNumber } from './MeasurementFormatter';
import { getSafe } from './Utils';
import i18n from './i18n';

export const MEASUREMENT_TREENODE_SEPARATOR = '!';

export const getMeasurementFromVariable = (variableName: string, measurements: Measurement[], parentMeasurement: Measurement): Measurement => {
  const possibleMeasurements = flow([
    measurements => filter(measurements, measurement => variableName === escapeVar(measurement.name)),
    // choose by priority if multiple measurements with same name:Category
    // propre sous categ, descendants sous categs, remonte un, tout sauf deha process, remonte un etc
    measurements => sortBy(measurements, (measurement: Measurement) => {
      if (measurement.category === parentMeasurement.category) {
        return measurement.subcategory === parentMeasurement.subcategory
          ? 0 // 0 means highest priorit
          : 1
      }

      return 2;
    })
  ])(measurements);

  // if same exact measurement is duplicated, can cause cycle dependencies to try to use it in formulas
  /*if (
    possibleMeasurements.length > 1 &&
    possibleMeasurements[0].category === possibleMeasurements[1].category &&
    possibleMeasurements[0].subcategory === possibleMeasurements[1].category
  ) {
    return null;
  }*/

  return first(possibleMeasurements);
};

export function addNewMeasurementToTask(task: Task, stores: Stores, batchParam?: WriteBatch, treeNode = stores.treeNodesStore.selectedTreeNode) {
  const batch = batchParam || firestoreBatch(stores);

  const { measurementsStore, treeNodesStore, tasksStore, userInfoStore } = stores;
  const { user } = userInfoStore;
  const providedQuantites = task.providingItem?.providedQuantities

  const newMeasurement = measurementsStore.createItem(Measurement);
  newMeasurement.name = i18n.t('Quantity');

  // for labour, quicker if we pick the sq.ft ou ft. instead of hours when available, when creating a brand new quantity
  const nonUnitUnitTypes = (user?.shouldPreferNonUnitUnitTypes || task.providingItem?.isLabour) ? [UnitType.Surface, UnitType.Length, UnitType.Volume, UnitType.Weight] : [];
  const nonUnitUnitTypeToUse = nonUnitUnitTypes.find(unitType => providedQuantites?.get(unitType)?.quantity);

  const timeProvidedQuantity = providedQuantites?.get(UnitType.Time);

  newMeasurement.unitType = nonUnitUnitTypeToUse || (timeProvidedQuantity?.quantity ? UnitType.Time : UnitType.Unit);
  newMeasurement.displayUnit = providedQuantites?.get(newMeasurement.unitType)?.unit || Unit.Unit;

  newMeasurement.isOneTimeUse = true;
  newMeasurement._defaultFormula = '1';

  newMeasurement.shouldApplyWaste = false;
  measurementsStore.batchAddEditItem(newMeasurement, batch);
  const newMeasurementValue = new MeasurementValue(stores, treeNode, newMeasurement, '1');

  treeNodesStore.applyMeasurementValues(treeNode, [newMeasurementValue], false, batch);
  treeNodesStore.batchAddEditItem(treeNode, batch);

  task.measurement = newMeasurement;
  tasksStore.batchAddEditItem(task, batch);

  if (!batchParam) {
    return batch.commit();
  } else {
    return Promise.resolve('');
  }
}

export const showCreateNewMeasurementDialog = (stores: Stores, newMeasurement = stores.measurementsStore.createItem(Measurement)): Measurement => {
  // avoids circular dependency
  const MeasurementEditDialog = require('components/common/MeasurementEditDialog/MeasurementEditDialog').default;
  const { measurementsStore, treeNodesStore, dialogsStore } = stores;
  const { categoryFilter, subcategoryFilter } = measurementsStore;

  newMeasurement.name = i18n.t('New measurement');
  if (categoryFilter) {
    // semi duplicate
    newMeasurement.category = categoryFilter;
    newMeasurement.subcategory = subcategoryFilter;
  }

  const newDialog = new Dialog(stores);
  newDialog.subtype = 'MeasurementEditDialog'; // ?
  newDialog.dialogComponent = ({ open }) => (
    <MeasurementEditDialog open={open} dialogId={newDialog.id} measurement={newMeasurement} treeNode={treeNodesStore.selectedTreeNode} />
  );
  dialogsStore.showDialog(newDialog);

  return newMeasurement;
}



export function getMeasurementValueAtNode(node: TreeNode, measurement: Measurement) {
  // Tasks list only save at root level.

  // already root
  if (node.ownMeasurementValues.has(measurement.id)) {
    return node.measurementValues.get(measurement.id).clone();
  }

  const rootMeasurementValue = (
    getSafe(() => node.measurementValues.get(measurement.id).clone()) ||
    new MeasurementValue(node.stores, node, measurement)
  );

  // Use measurement default value or in the special case of a summable number value which gets divided evenly between leaves
  // use the result number

  // for summable number value which gets divided evenly between leaves, get the total
  // for same as children value, we can take the first child value
  const bubbleChildMeasurementValue =
    node.getBubbleDescendantsWithOwnMeasurement(measurement)
      .map(descendant => descendant.measurementValues.get(measurement.id))
      .find(measurementValue => !formulaHasVariables(measurementValue.formula));

  if (
    bubbleChildMeasurementValue
  ) {
    rootMeasurementValue.formula = '' + MeasurementConverter.convert(measurement.isSummable ? rootMeasurementValue.metricValue : bubbleChildMeasurementValue.metricValue, Unit.DefaultMetric, measurement.displayUnit);
  }

  return rootMeasurementValue;
}

export function getMeasurementsWithDependencies(measurements: Measurement[]): Measurement[] {
  return uniqBy([
    ...flatten(compact(measurements).map(measurement => measurement.measurementDependencies)),
    ...compact(measurements),
  ], 'id')
}

export function getMeasurementsWithDependenciesIncludingOptional(measurements: Measurement[]): Measurement[] {
  return uniqBy([
    ...flatten(compact(measurements).map(measurement => measurement.measurementDependenciesIncludingOptional)),
    ...compact(measurements),
  ], 'id')
}

export function shouldUseAsDefaultFormula(isNewMeasurement = false, newFormula: string, previousDefaultFormula: string, isQuantity = true) {
  return (
    isNewMeasurement ||
    (
      formulaHasVariables(newFormula) &&
      !formulaIsBubbleFunction(newFormula) &&
      !newFormula.includes(COUNT_FUNCTION)
    ) || (
      // if changing a number to another number (ex. 4 becomes 5), 5 becomes the new default value
      // except if previous value was 0, we assume we want to set the value manually each time
      // however, this logic doesn't apply when 0 represents an enum or an angle, not a quantity
      !formulaHasVariables(previousDefaultFormula) && (previousDefaultFormula !== '0' || !isQuantity))
  );
}

export function getFormulaType(formula: string, metricValue?: number): FormulaType {
  if (formula === null || formula === undefined) {
    return FormulaType.ComputedParameter;
  }

  if (formulaIsFromDrawing(formula)) {
    return metricValue !== 0
      ? FormulaType.ComputedParameter
      : FormulaType.UserParameter;
  }

  if (
    isEmpty(formula) ||
    formula === `${SAME_AS_CHILDREN_FUNCTION}()` ||
    formulaIsNumber(formula) /*&& !this.measurement.isSummable*/
  ) {
    return FormulaType.UserParameter;
  }

  return FormulaType.ComputedParameter;
}

export const showActiveMeasurementsDialog = (context: Stores) => () => {
  const MeasurementsListDialog = require('components/common/MeasurementsListDialog/MeasurementsListDialog').default;
  const { dialogsStore, measurementsStore } = context;
  measurementsStore.selectedItems.clear();
  const newDialog = new Dialog(context);
  newDialog.dialogComponent = ({ open }) => (
    <StoresContext.Provider value={Globals.getTemporaryStores(context)}>
      <MeasurementsListDialog open={open} dialogId={newDialog.id} />
    </StoresContext.Provider>
  );
  dialogsStore.showDialog(newDialog);
}


export const handleChangeActiveMeasurements = (context: Stores) => {
  const { measurementsStore, treeNodesStore } = context;
  const { selectedTreeNode } = treeNodesStore;

  const previouslySelectedMeasurements = selectedTreeNode.measurementsToShow;
  const measurementsToActivate = measurementsStore.selectedItemsArray
    .filter(measurement => !previouslySelectedMeasurements.includes(measurement));

  const measurementsToDeactivate = previouslySelectedMeasurements
    .filter(measurement => !measurementsStore.selectedItemsArray.includes(measurement));

  const batch = firestoreBatch(context);
  treeNodesStore.toggleNodeMeasurements(
    measurementsToDeactivate,
    false,
    selectedTreeNode,
    true,
    batch
  );

  treeNodesStore.toggleNodeMeasurements(
    measurementsToActivate,
    true,
    selectedTreeNode,
    true,
    batch
  );

  batch.commit();
}

// RETURNS A FUNCTION!!!
export const duplicateMeasurement = (measurement: Measurement, stores: Stores, dialogId: string = '') => {
  return duplicateMeasurements([measurement], stores, dialogId);
}

// RETURNS A FUNCTION!!!!!
export const duplicateMeasurements = (measurements: Measurement[], stores: Stores, dialogId: string = '') => () => {
  const { treeNodesStore, measurementsStore, dialogsStore, userInfoStore } = stores;

  const batch = firestoreBatch(stores);

  const itemCopies = measurements.map(measurement => {
    const measurementCopy = measurement.clone(measurement.stores, uuidv4());
    measurementCopy.cascadeOrders.clear();
    measurementCopy._isReadonly = false;
    measurementCopy.owner = userInfoStore.nonImpersonatedEmail;
    if (!measurement.isOneTimeUse) {
      measurementCopy.name += ' ' + i18n.t('(Copy)');
    }
    return measurementCopy;
  });

  measurementsStore.batchAddEditItems(itemCopies, batch);

  treeNodesStore.toggleNodeMeasurements(itemCopies, undefined, undefined, false, batch);

  batch.commit();

  if (dialogId) {
    dialogsStore.hideDialog(dialogId);
  }

  return itemCopies;
}

export const showMeasurementDialog = (measurement: Measurement, treeNode?: TreeNode, task?: Task) => {
  // in drawing dialog, measurement doesnt have correct context, only treenode
  const context = treeNode?.stores || measurement.stores;
  const { dialogsStore } = context;
  // don't show when another measurement dialog is already open
  if (dialogsStore?.topMostDialog?.subtype === 'MeasurementEditDialog') {
    return;
  }

  const newDialog = new Dialog(context);
  newDialog.subtype = 'MeasurementEditDialog';
  newDialog.dialogComponent = ({ open }) => (
    <MeasurementEditDialog open={open} dialogId={newDialog.id} measurement={measurement} treeNode={treeNode} task={task} />
  )
  dialogsStore.showDialog(newDialog);
}

const getSlopeIcon = (slopeDirection: number) => slopeDirection === -1
  ? null
  : (
    // zero is assumed to be right, not left, so needs an extra 180 degree
    // also css rotation is clockwise, or calculation is counterclockwise
    <ArrowLeftIcon style={{ transform: `rotate(${(-slopeDirection + Math.PI)}rad)` }} />
  );

export const getProjectionName = (projectionIndex: number) => (
  getSafe(() => i18n.t(Object.values(DrawingProjections)[projectionIndex])) ||
  i18n.t(DrawingProjections.Elevation)
);

export const getMeasuremementCustomIconFunction = (measurementType: MeasurementType) => {
  switch (measurementType) {
    case MeasurementType.RoofSlopeDirection:
      return getSlopeIcon;

    default:
      return null;
  }
}

export const getMeasuremementCustomFormatter = (measurementType: MeasurementType) => {
  switch (measurementType) {
    case MeasurementType.RoofSlopeDirection:
      return (value: number) => value === -1 ? i18n.t('Same as lines') : ''; //`${value / Math.PI * 180} deg.`;

    case MeasurementType.DrawingProjection:
      return getProjectionName;

    default:
      return null;
  }
}

export const getMeasurementCustomSelectOptions = (measurementType: MeasurementType): number[] => {
  switch (measurementType) {
    case MeasurementType.RoofSlopeDirection:
      let allowedSlopeDirections = [-1 /* special value to detect direction automatically */];
      // 0 is right ->
      for (let i = 0; i < 8; i++) {
        allowedSlopeDirections.push(i * 45 / 180 * Math.PI);
      }
      return allowedSlopeDirections;

    case MeasurementType.DrawingProjection:
      return Object.values(DrawingProjections).map((value, index) => index);

    default:
      return null;
  }
}

export const getDrawingProjectionValue = (drawingProjection: DrawingProjections): number => {
  if (drawingProjection === DrawingProjections.Plan) {
    return 0;
  }

  return 1;
}

export const measurementHasDefaultName = (measurement: Measurement) => (
  [
    ...Object.values(i18n.tAll('Quantity')),
    ...Object.values(i18n.tAll('Fixed Price')),
  ].includes(measurement.name)
)

export const onMeasurementDrop = (task: Task) => (event: React.DragEvent<HTMLElement>) => {
  if (!task) {
    return;
  }

  const { dragAndDropStore, tasksStore, measurementsStore, dialogsStore } = task.stores;

  if (!(dragAndDropStore.dragObject instanceof Measurement)) {
    dragAndDropStore.dragObject = null;
    return;
  }

  const measurement: Measurement = dragAndDropStore.dragObject as Measurement;

  // if many tasks are checked and all are empty, allow to drag on measurement to multiple tasks
  if (tasksStore.selectedItems.has(task)) {
    const newDialog = new Dialog(task.stores);
    newDialog.dialogComponent = ({ open }) => (
      <ConfirmDialog
        open={open}
        dialogId={newDialog.id}
        onConfirm={() => {
          tasksStore.selectedItems.forEach(t => {
            t.measurement = measurement
          });
          tasksStore.addEditItems(tasksStore.selectedItemsArray, true, ['measurementId']);
        }}
        onClose={() => {
          task.measurement = measurement;
          task.adjustmentValue = 0;
          task.adjustmentType = AdjustmentType.Add;
          tasksStore.addEditItem(task, true, ['measurementId', 'adjustment']);
          tasksStore.selectedItems.clear();
        }}
        title={i18n.t('Also replace measurements from other selected tasks ?')}
        noLabel={i18n.t('Replace for this one task only')}
        yesLabel={i18n.t('Replace in all checked tasks')}
      />
    );
    dialogsStore.showDialog(newDialog);
  } else {
    task.measurement = measurement;
    task.adjustmentValue = 0;
    task.adjustmentType = AdjustmentType.Add;
    tasksStore.addEditItem(task, true, ['measurementId', 'adjustment']);
  }

  measurementsStore.ensureSavedInProjectCollection(getMeasurementsWithDependencies([measurement]));

  dragAndDropStore.dragObject = null;
}

