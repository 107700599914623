import { BuiltinMeasurementCategories } from 'constants/BuiltinCategories';
import BuiltinMeasurements from 'constants/BuiltinMeasurements';
import { DbLocationType } from 'constants/DbLocationType';
import { Dictionary, compact, isEmpty, mapValues, pickBy } from 'lodash';
import { action, computed, observable, reaction } from 'mobx';
import Category from 'models/Category';
import Measurement from 'models/Measurement';
import { flattenItemsByCategSubcateg, groupItemsByCategSubcategSubcateg } from 'utils/CategoryUtil';
import { CollectionReference, WriteBatch, firestore } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { areModelsDifferent, getSafe, shouldUseCache } from 'utils/Utils';
import SearchableFirebaseStore from './SearchableFirebaseStore';

// confusion about "selectedItems" which are both checked measurements in the list and active measurements 
// (measurements being applied on current treeNode search measurementsToShow
// Both use measurementsStore.selectedItems, but 2 different measurementsStore contexts.
// the measurements of the current treeNode are synced in a reaction see syncActiveMeasurementsSelectionUnsubscribe
export default class MeasurementsStore extends SearchableFirebaseStore<Measurement> {
  storeKey = 'measurements';

  dbLocationsTypes = new Set(compact([
    DbLocationType.Master,
    DbLocationType.MasterProject,
    DbLocationType.User,
    this.accountParam && DbLocationType.ExternalUser,
    DbLocationType.Project
  ]));

  shouldKeepUserItems = true;

  shouldTrackModifDate = true;
  shouldTrackOwner = true;

  @observable isCategoriesToolbarOpen = false;
  @observable toolbarSelectedCategory = null;

  @observable measurementBeingEdited: Measurement = null;

  /* override */ getItemsImpl(): Measurement[] {
    return super.getItemsImpl()
      .filter(item => !item.isOneTimeUse)
      .sort((a, b) => a.name?.localeCompare?.(b?.name))
  }

  /* override */ addCachedMasterItems() {
    if (!shouldUseCache()) {
      return;
    }

    const { user } = this.stores.userInfoStore;

    let cachedMasterData = require('assets-sw/db-cache/measurements.json');

    if (user?.shouldUseBareMinimumMasterItems) {
      cachedMasterData = pickBy(cachedMasterData, (item, key) => ([
        BuiltinMeasurements.GeneralLength,
        BuiltinMeasurements.GeneralSurface,
      ].includes(key)))
    }

    this.cachedMasterData = cachedMasterData;

    this.applyCachedData(this.cachedMasterData);
  }

  /* override */
  saveToDb(items: Measurement[], collections: CollectionReference[], fieldsToUpdate, batchParam: WriteBatch = null) {
    const batch = batchParam || firestoreBatch(this.stores);

    const oneTimeUseItems = items.filter(item => item.isOneTimeUse);
    const otherItems = items.filter(item => !item.isOneTimeUse);

    // only save to project collection when it is a Quantity type measurement (ie. single use)
    !isEmpty(oneTimeUseItems) && super.saveToDb(oneTimeUseItems, [this.projectCollection], fieldsToUpdate, batchParam);
    // save to all collections otherwise
    !isEmpty(otherItems) && super.saveToDb(otherItems, collections, fieldsToUpdate, batchParam);

    if (!batchParam) {
      batch.commit();
    }
  }

// don't allow modifying reference measurements
// TODO: error message, revert item from db
/* override */ addEditItemsImpl(items: Measurement[], shouldSaveToDb = true, fieldsToUpdate: (keyof Measurement)[] = null, batchParam: WriteBatch = null) {
    const { userInfoStore } = this.stores;
    const allowedItems = userInfoStore.userEmail === 'master'
      ? items
      : items.filter(item => (
        item.categoryId === BuiltinMeasurementCategories.ReferenceMeasurements ||
        item.id !== BuiltinMeasurements.GeneralLength ||
        item.id !== BuiltinMeasurements.GeneralSurface
      ));

    super.addEditItemsImpl(allowedItems, shouldSaveToDb, fieldsToUpdate, batchParam);
  }

  @action
  deleteItem(id: ModelId, shouldSaveToDb: boolean = true) {
    // delete from nodes
    if (shouldSaveToDb) {
      const { treeNodesStore } = this.stores;
      const batch = firestoreBatch(this.stores);
      treeNodesStore.allNodes.forEach(node => {
        if (node.ownMeasurementValues.has(id)) {
          node.ownMeasurementValues.delete(id);
          treeNodesStore.addEditItem(node, true, ['ownMeasurementValues'], batch);
        }
      });

      batch.commit();
    }

    return super.deleteItem(id, shouldSaveToDb);
  }


  get searchedFilteredItems() {
    return this.searchedItems
      // duplicate....
      .filter(measurement => (
        !measurement.isOneTimeUse &&
        (this.shouldShowHiddenItems || !measurement.isHidden)
      ));
  }


  // put in treenodestore?
  @computed get activeAndInactiveMeasurements(): { activeMeasurements: Measurement[], inactiveMeasurements: Measurement[] } {
    const treeNodesStore = this.stores.treeNodesStore;

    let activeMeasurements = [];
    let inactiveMeasurements = [];

    // compare ids because in drawing mode, we compare 2 different stores (might be a problem)
    const activeMeasurementIdsForSelectedNode = getSafe(() => treeNodesStore.selectedTreeNode.measurementsToShow.map(m => m.id)) || [];

    // O(2)
    this.searchedFilteredItems
      .forEach(measurement => {
        if (activeMeasurementIdsForSelectedNode.includes(measurement.id)) {
          activeMeasurements.push(measurement);
        } else {
          inactiveMeasurements.push(measurement);
        }
      });

    return { activeMeasurements, inactiveMeasurements };
  }

  @computed get activeMeasurements() {
    // exclude general category measurements from being shown unless in the advanced measurements dialog
    // not sure needed yet
    //return this.activeAndInactiveMeasurements.activeMeasurements.filter(m => m.category && m.categoryId !=== 'General');

    return this.activeAndInactiveMeasurements.activeMeasurements
  }

  @computed get inactiveMeasurements() {
    return this.activeAndInactiveMeasurements.inactiveMeasurements;
  }

  // everything here is for selected node so probably bad name
  @computed get groupedActiveMeasurementsForSelectedNode() {
    return groupItemsByCategSubcategSubcateg(this.activeMeasurements);
  }

  @computed get groupedActiveMeasurementsFlattenedForSelectedNode() {
    return flattenItemsByCategSubcateg(this.groupedActiveMeasurementsForSelectedNode, this.stores, true);
  }

  @computed get groupedMeasurementsForSelectedNode() {
    return groupItemsByCategSubcategSubcateg(this.searchedFilteredItems);
  }

  @computed get groupedMeasurementsFlattenedForSelectedNode() {
    return flattenItemsByCategSubcateg(this.groupedMeasurementsForSelectedNode, this.stores, true);
  }

  @computed get subcategoriesByCategoryShownInToolbar(): Dictionary<Category[]> {
    return mapValues(
      this.subcategoriesByCategory,
      (subcategs, categ) => subcategs.filter(subcateg => this.itemsByCategSubcateg[categ]?.[subcateg.id]?.every(item => item.canBeToggledFromToolbar)))
      ;
  }

  DEBUG_diffMeasurementsToCacheMaster = async () => {
    const measurementsDiff = [];

    await this.items.filter(measurement => measurement.cascadeOrders.has(0)).reduce(async (previousTask, measurement) => {
      await previousTask;

      const masterMeasurement = this.masterCollectionItemsMap.get(measurement.id);
      if (areModelsDifferent(masterMeasurement, measurement)) {
        measurementsDiff.push({
          id: measurement.id,
          masterName: masterMeasurement.name,
          masterFormula: masterMeasurement._defaultFormula,
          name: measurement.name,
          formula: measurement._defaultFormula,
        })
      }

      return '';
    }, Promise.resolve(''));

    return measurementsDiff.filter(diff => diff.masterName !== diff.name || diff.formula !== diff.masterFormula);
  }

  DEBUG__deleteAllUserLevelMeasurement = () => {
    return;

    const { userInfoStore } = this.stores;
    const db = firestore();
    const batch = firestoreBatch(this.stores);

    const users = userInfoStore.items.filter(i => i.id !== 'master');

    // this was used to fix bad formula in measurement and to avoid people getting it in their next projet
    users.forEach(user => {
      batch.delete(db.doc(`/users/${user.id}/measurements/WindowFraming`));
    });

    batch.commit();
  }

  DEBUG_deleteProjectLevelNonOneTimeUseMeasurements = async () => {
    return;
    const { projectsStore, tasksListsStore, measurementsStore, userInfoStore } = this.stores;

    const db = firestore();
    const batch = firestoreBatch(this.stores);

    const projectIds = [...projectsStore.items.map(i => i.id), ...tasksListsStore.items.filter(i => i.cascadeOrder !== 0).map(i => i.id)];

    const nonOneTimeUseMeasurementIds = measurementsStore.items.filter(m => !m.isOneTimeUse).map(m => m.id);

    debugger;

    await projectIds.reduce(async (previousTask, projectId) => {
      await previousTask;

      const projectsMeasurements = await (await db.collection(`/users/${userInfoStore.userEmail}/projects/${projectId}/measurements`).get()).docs.map(doc => doc.id);

      const measurementsIdsToDelete = projectsMeasurements.filter(pm => nonOneTimeUseMeasurementIds.includes(pm));

      measurementsIdsToDelete.forEach(measurementId => {
        batch.delete(db.doc(`/users/${userInfoStore.userEmail}/projects/${projectId}/measurements/${measurementId}`));
      })

      return '';
    }, Promise.resolve(''));

    batch.commit();
  }

  /* override */ attemptLoadItems() {
    if (this.isLoading) {
      return;
    }

    if (
      !this.stores.treeNodesStore.isReady
    ) {
      return;
    }

    super.attemptLoadItems();
  }

  priorityLoadCheck = reaction(() => (
    this.stores?.treeNodesStore?.isReady
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}


