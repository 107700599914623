
import * as classnames from 'classnames';
import * as React from 'react';
import { allowDrop } from 'utils/DragUtils';
import ObserverComponent from '../ObserverComponent';

const styles = require('./DroppableDiv.module.scss');

// We don't use react-beautiful-dnd here because it won't let
// items be dragged across an iframe

interface DroppableDivProps {
  className?: string,
  classNameIsDragging?: string,
  classNameIsDraggingOver?: string,
  shouldEnable: boolean, // allow to accept only certain types of dragObject
  onDrop: (event: React.DragEvent<HTMLElement>) => void,
  onClick?: (event) => void,
}

interface DroppableDivState {
  isDraggingOver: boolean,
}

export default class DroppableDiv extends ObserverComponent<DroppableDivProps | HTMLDivElement, DroppableDivState> {
  static defaultProps = {
    className: '',
    classNameIsDragging: '',
    classNameIsDraggingOver: '',
    onClick: () => { ; }
  }

  state = {
    isDraggingOver: false,
  }

  onDragEnter = () => {
    this.setState({ isDraggingOver: true });
  }

  onDragLeave = () => {
    this.setState({ isDraggingOver: false });
  }

  onDrop = (event: React.DragEvent<HTMLElement>) => {
    // on touch screen laptops, it tries to open a new page with the item id
    event.preventDefault();

    this.setState({ isDraggingOver: false });

    this.props.onDrop?.(event);
  }

  _render() {
    const { dragAndDropStore } = this.context;
    const { children, className, classNameIsDragging, classNameIsDraggingOver, shouldEnable, onClick, onDrop, ...otherProps } = this.props;
    const { isDraggingOver } = this.state;
    const isDragging = dragAndDropStore.isDragging;

    return shouldEnable ? (
      <div
        className={classnames(styles.root, className, {
          [styles.isDragging]: isDragging,
          [classNameIsDragging]: isDragging,
          [styles.isDraggingOver]: isDraggingOver,
          [classNameIsDraggingOver]: isDraggingOver,
        })}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onDragOver={allowDrop}
        {...otherProps}
      >
        {children}
      </div >
    ) : (
      <div
        onClick={onClick}
        className={classnames(styles.root, className)}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
}
