import { Avatar, Button, IconButton, Tooltip } from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountBox';
import BackIcon from '@material-ui/icons/ArrowBack';
import FeedbackIcon from '@material-ui/icons/LiveHelp';
import BillingIcon from '@material-ui/icons/Payment';
import RedoIcon from '@material-ui/icons/Redo';
import SettingsIcon from '@material-ui/icons/Settings';
import StarIcon from '@material-ui/icons/Star';
import UndoIcon from '@material-ui/icons/Undo';
import PasswordIcon from '@material-ui/icons/VpnKey';
import classnames from 'classnames';
import CleanupProjectDialog from 'components/common/CleanupProjectDialog/CleanupProjectDialog';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import Editable from 'components/common/Editable/Editable';
import { HelmetIcon, LogoutIcon, MopIcon, PdfIcon, ToolsIcon } from 'components/common/Icons';
import MenuPopupButton from 'components/common/MenuPopupButton/MenuPopupButton';
import ObserverComponent from 'components/common/ObserverComponent';
import SettingsEditDialog from 'components/common/SettingsEditDialog/SettingsEditDialog';
import ReportNav from 'components/reports/ReportNav/ReportNav';
import { ReportTabs } from 'constants/ReportTabs';
import { environment, isProduction } from 'environment';
import { compact, defer } from 'lodash';
import Dialog from 'models/Dialog';
import Project from 'models/Project';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import i18n from 'utils/i18n';
import { encodeProjectName } from 'utils/ProjectUtils';
import { showProvidingItemRatesChangeDialog } from 'utils/ProvidingItemUtil';
import { showUpgradeSubscriptionDialog } from 'utils/SubscriptionUtil';
import { getSafe } from 'utils/Utils';

const prodLogo = require('../../assets-sw/images/logo.svg');
const devLogo = require('../../assets-sw/images/logo-dev.svg');


const VERSION_SUFFIX = isProduction() ? '' : 'Dev';

// todo: replace [] by react fragments

const styles = require('./HeaderView.module.scss');

interface HeaderViewState {
  isRenamingProject: boolean,
}

class HeaderView extends ObserverComponent<RouteComponentProps<{ [projectId: string]: string }>, HeaderViewState> {
  state = {
    isRenamingProject: false
  }

  showProjectSettings = () => {
    const { settingsStore, dialogsStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <SettingsEditDialog open={open} dialogId={newDialog.id} settings={settingsStore.settings} />
    )
    dialogsStore.showDialog(newDialog);

    //SettingsStore.toggleImperialOrMetric();
  }

  goToReportView = () => {
    this.props.history.push(this.props.location.pathname + '/report' + this.props.location.search);
  }

  goToProjectView = () => {
    const { reportsStore } = this.context;
    reportsStore.selectedTab = ReportTabs.DESIGN;
    this.props.history.push(this.props.location.pathname.replace('/report', '') + this.props.location.search);
  }

  applyNewProjectName = (project: Project) => {
    const { history, location } = this.props;
    // exception is for when wanting to go to projects list just before exit edit mode
    // TODO: Fix country code
    if (history.location.pathname !== '/ca/projects') {
      history.replace(`/ca/projects/${project.encodedName}` + location.search);
    }
  }

  sendFeedback = () => {
    const { userInfoStore } = this.context;
    const { user } = userInfoStore;

    window.Intercom?.('show');
  }

  askToResetPassword = () => {
    const { dialogsStore, userInfoStore } = this.context;
    const newDialog = new Dialog(this.context);

    newDialog.dialogComponent = ({ open }) => (
      <ConfirmDialog
        open={open}
        dialogId={newDialog.id}
        onConfirm={userInfoStore.resetPassword}
        title={i18n.t('Reset Password')}
        content={<>
          <p>
            {i18n.t('You will receive an email to {{email}} with a link to reset your password.', { email: userInfoStore.nonImpersonatedEmail })}
          </p>
          <p>
            {i18n.t('You will also be disconnected from this session. Do you want to continue?')}
          </p>
        </>}
        yesLabel={i18n.t('Yes, send reset password email')}
      />
    );

    dialogsStore.showDialog(newDialog);
  }

  viewBillingZone = () => {
    const { userInfoStore } = this.context;
    window.open(`https://evalumo.com/zone-client/${userInfoStore.user.quadernoUserId}`, '_blank');
  }

  showCleanupProjectDialog = () => {
    const { dialogsStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <CleanupProjectDialog
        open={open}
        dialogId={newDialog.id}
      />
    )
    dialogsStore.showDialog(newDialog);
  }

  _render() {
    const { userInfoStore, treeNodesStore, projectsStore, commonStore, routerStore, subscriptionsStore, undoStore } = this.context;
    const { isReportPage, isProjectView } = routerStore;
    const { isRenamingProject } = this.state;
    const { user, userEmail, nonImpersonatedEmail } = userInfoStore;

    // not as clean as it could be
    const isProjectsSelectionPage = !this.props.match.params.encodedProjectName;
    const selectedNode = treeNodesStore.selectedTreeNode;
    const { selectedProject } = projectsStore;
    const { encodedProjectName } = this.props.match.params;
    const { APP_NAME } = environment;

    // bad place to put this side effect
    if (
      projectsStore.isReady &&
      encodedProjectName &&
      encodedProjectName !== getSafe(() => encodeProjectName(selectedProject.name))
    ) {
      defer(() => {
        const index = routerStore.queryParamsSettings.get('index') || 0;
        const projectId = projectsStore.filterByEncodedName(encodedProjectName)?.[index]?.id;
        if (projectId) {
          commonStore.selectedProjectId = projectId;
        } else {
          this.props.history.replace('/ca/projects' + this.props.location.search);
        }
      });
    }

    const photoURL = getSafe(() => user.photoURL);

    const accountIcon = photoURL
      ? <img src={photoURL} style={{ width: "100%", height: "100%" }} />
      : <AccountIcon />

    const accountName = (
      nonImpersonatedEmail !== userEmail
        ? `${nonImpersonatedEmail} (${userEmail})`
        : userEmail
    ) || '';

    return (
      <div
        className={classnames(
          styles.root,
          {
            [styles.rootStretchWidth]: !isProjectsSelectionPage,
            [styles.isRenamingProject]: isRenamingProject
          }
        )}>

        <Helmet>
          <title>{selectedProject ? selectedProject.name + ' —' : ''} {APP_NAME} {isProduction() ? '' : i18n.t('Development Version')}</title>
        </Helmet>

        <nav className={styles.nav}>
          {/* Todo fix country code */}
          <Link to={`/ca/projects${location.search}`}>
            <div className={styles.logo}>
              <img src={isProduction() ? prodLogo : devLogo} />

              {!selectedProject && (
                <div className={styles.appName}>
                  {APP_NAME}
                </div>
              )}
              {(!selectedProject || !isProduction()) && VERSION_SUFFIX && (
                <div className={classnames(styles.version, { [styles.isProduction]: isProduction() })}>
                  {VERSION_SUFFIX}
                </div>
              )}
            </div>

          </Link>

          {selectedProject && (
            <>
              <div className={classnames(styles.name, { ['hidden-phone']: isReportPage })} style={{ marginBottom: 0 }}>
                <div className={styles.ancestor}>
                  <Editable
                    isEditable={!isReportPage} // crashes when renaming and generating pdf at the same time
                    id="projectName"
                    editLabel={i18n.t('Project name')}
                    model={selectedProject}
                    propertyName="name"
                    onEditModeChange={(isEditMode) => this.setState({ isRenamingProject: isEditMode })}
                    //shouldEditOnMount={selectedProject.isUntitled} // app is not very responsive when loading project
                    onSave={(projectCopy: Project) => {
                      if (
                        projectCopy.name !== selectedProject.name &&
                        encodeProjectName(projectCopy.name) // don't allow empty names
                      ) {
                        projectsStore.addEditItem(projectCopy, true, ['name']);
                        this.applyNewProjectName(projectCopy as Project)
                      }
                    }}
                    className={classnames(styles.projectNameField, { [styles.isEditable]: !isReportPage })}
                    classNameEditMode={styles.projectNameFieldEdit}
                    InputClassName={styles.projectNameFieldInput}
                    displayComponent={
                      <Link to={`/ca/projects/${encodedProjectName}${location.search}`}>
                        {selectedProject.name}
                      </Link>
                    }
                  />
                </div>
                {/*<IconButton title="Create a copy" onClick={() => askToCopyObject(selectedProject)} className={styles.copyIcon}>
                <CopyIcon />
          </IconButton>*/}
              </div>

              {isReportPage
                ? (<>
                  <Tooltip title={i18n.t('Back to project editing')}>
                    <Button variant="outlined" color="secondary" className={styles.actionButton + ' ' + styles.backButton} onClick={this.goToProjectView} >
                      <BackIcon />
                      &nbsp;
                      <span className='hidden-phone'>{i18n.t('Back to project editing')}</span>
                    </Button>
                  </Tooltip>

                  <div className={styles.reportNav + ' visible-phone'}><ReportNav /></div>
                </>
                ) : selectedNode && (
                  <div className={styles.selectedNodePath}>
                    {
                      selectedNode.ancestors.slice(0).reverse()
                        // display last 2 ancestors
                        .slice(1 + Math.max(0, selectedNode.ancestors.length - 3), selectedNode.ancestors.length)
                        .map((ancestor, index) => (
                          <div role="button" style={{ cursor: 'pointer' }} className={styles.ancestor} key={index} onClick={() => treeNodesStore.selectedTreeNode = ancestor}>
                            {ancestor.name}
                          </div>
                        ))
                    }
                    <div className={styles.selectedNodeName}>
                      {selectedNode.text}
                    </div>
                  </div>
                )}
            </>)}
          <div style={{ flex: 1 }} />

          {subscriptionsStore.isTrial && (
            <Button className={styles.upgradeButton + ' hidden-phone'} onClick={showUpgradeSubscriptionDialog(this.context)} variant="contained" color="primary">
              <StarIcon />&nbsp; {i18n.t('Upgrade')}
            </Button>
          )}

          <div className={styles.undoRedo}>
            <Tooltip title={i18n.t('Undo')}>
              <span> {/* required by tooltip if button can be disabled */}
                <IconButton className={styles.actionButton} disabled={!undoStore.canUndo} onClick={undoStore.undo}>
                  <UndoIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={i18n.t('Redo')}>
              <span>
                <IconButton className={styles.actionButton} disabled={!undoStore.canRedo} onClick={undoStore.redo} >
                  <RedoIcon />
                </IconButton>
              </span>
            </Tooltip>
          </div>

          {selectedProject && !isReportPage && (
            <>
              <Tooltip title={i18n.t('View and send estimates and invoices')} key="hv2">
                <Button color="primary" className={styles.actionButton + ' ' + styles.sendEstimateButton} onClick={this.goToReportView} >
                  <PdfIcon />
                  <span className={styles.buttonText}>&nbsp;{i18n.t('Reports')}</span>
                </Button>
              </Tooltip>
            </>)}

          <span className="hidden-phone">
            <Tooltip title={i18n.t('Send your feedback or question')} key="feedback">
              <IconButton id="intercomLauncher" className={styles.actionButton}>
                <FeedbackIcon />
              </IconButton>
            </Tooltip>
          </span>

          {isProjectView && (
            <div
              id="toolsButtonWip"
              className="hidden-phone"
              style={{ display: 'none' }}
            >
              <MenuPopupButton
                className={styles.toolsButton}
                title={i18n.t('Tools')}
                icon={
                  <ToolsIcon />
                }
                menuItems={[
                  { icon: <MopIcon />, text: i18n.t('Project Cleanup') + '...', handler: this.showCleanupProjectDialog },
                  { icon: <HelmetIcon />, text: i18n.t('Modify labour rates') + '...', handler: showProvidingItemRatesChangeDialog(this.context) },
                ]} />
            </div>
          )}

          <span className="hidden-phone">
            <Tooltip title={i18n.t('Settings')} key="hv3">
              <IconButton onClick={this.showProjectSettings} className={styles.actionButton}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </span>

          <MenuPopupButton
            className={styles.matCardAvatar}
            menuClassName={styles.accountMenu}
            title={accountName}
            icon={accountIcon}
            menuItems={compact([
              {
                icon: <Avatar className={styles.listAvatar}>{accountIcon}</Avatar>,
                text: <span className={styles.listAccountName}>{accountName}</span>,
              },
              { icon: <PasswordIcon />, text: i18n.t('Reset Password'), handler: this.askToResetPassword },
              user?.quadernoUserId && { icon: <BillingIcon />, text: i18n.t('Invoices and payment method'), handler: this.viewBillingZone },
              {
                className: 'visible-phone',
                icon: <FeedbackIcon />,
                text: i18n.t('Questions or feedback'),
                handler: this.sendFeedback
              },
              {
                className: 'visible-phone',
                icon: <SettingsIcon />,
                text: i18n.t('Settings'),
                handler: this.showProjectSettings
              },
              { icon: <LogoutIcon />, text: i18n.t('Logout'), handler: userInfoStore.logout },
            ])} />
        </nav>
      </div >
    );
  }
}

export default HeaderView;