/// UNUSED


import ObserverComponent from 'components/common/ObserverComponent';
import { DrawToolType } from 'constants/DrawToolType';
import { first, isEmpty, throttle } from 'lodash';
import { action, computed, observable } from 'mobx';
import Point from 'models/Point';
import SimpleSurface from 'models/SimpleSurface';
import Surface from 'models/Surface';
import * as React from 'react';
import { cursorToLocalPoint } from 'utils/Coords';
import { MOUSE_MOVE_THROTTLE_TIME, drawToolMouseMoveThrottledBase } from 'utils/DrawToolsUtils';
import { getSafe } from 'utils/Utils';

const styles = require('./AddHoleTool.module.scss');

export default class AddHoleTool extends ObserverComponent {
  svgTag: SVGSVGElement;

  @observable selectedHole: SimpleSurface = null;

  @computed get surface() {
    const { treeNodesStore } = this.context;
    const surface = treeNodesStore.selectedTreeNode?.shape;

    return surface instanceof Surface ? surface : null;
  }

  componentDidMount() {
    const { settingsStore } = this.context;
    this.svgTag.addEventListener(settingsStore.isTouchDevice ? 'touchend' : 'click', this.mouseDown);
    window.addEventListener(settingsStore.isTouchDevice ? 'touchmove' : 'mousemove', this.mouseMove);
    window.document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { settingsStore } = this.context;
    this.svgTag.removeEventListener(settingsStore.isTouchDevice ? 'touchend' : 'click', this.mouseDown);
    window.removeEventListener(settingsStore.isTouchDevice ? 'touchmove' : 'mousemove', this.mouseMove);
    window.document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key.toLowerCase() === 'z' && (e.metaKey || e.ctrlKey)) {
      // todo undo
    } else if (e.key === 'Escape') {
      this.finalizeClosedShape();
    }
  }

  finalizeClosedShape() {
    const { drawToolsStore, shapesStore, commonStore } = this.context;
    shapesStore.addEditItem(this.surface);

    commonStore.isBigUpdateOngoing = false;
    drawToolsStore.selectedTool = DrawToolType.Select;
  }

  @action.bound
  mouseDown(ev: MouseEvent) {
    const { shapesStore, commonStore } = this.context;
    if (
      // duplicate
      ev.ctrlKey ||
      ev.button !== 0 ||
      shapesStore.isDragPanningOnDrawing ||
      (ev?.target as HTMLElement)?.tagName === 'INPUT'
    ) {
      return;
    }

    if (!this.surface) {
      return;
    }

    commonStore.isBigUpdateOngoing = true;

    // Should use snap!!
    const localPt = cursorToLocalPoint(ev, this.svgTag);

    if (!this.selectedHole) {
      this.selectedHole = new SimpleSurface();
      this.surface.holes.push(this.selectedHole);
    }

    if (this.selectedHole.points.length > 1 && first(this.selectedHole.points).distancePixels(localPt) < 10) {
      this.finalizeClosedShape();
      return;
    }

    if (isEmpty(this.selectedHole.points)) {
      this.selectedHole.points.push(localPt.clone());
    } else {
      const previousPt = localPt.clone();
      this.selectedHole.points[this.selectedHole.points.length - 1] = previousPt;
    }

    const newPoint = localPt.clone();
    newPoint.isSnappable = false;

    this.selectedHole.points.push(newPoint);
  }


  @action.bound
  mouseMove(ev: MouseEvent) {
    this.mouseMoveThrottled(ev);
  }

  @action
  mouseMoveThrottled = throttle((ev: MouseEvent) => {
    const { snapStore } = this.context;

    if (!this.surface) {
      return;
    }

    const cursorPt = cursorToLocalPoint(ev, this.svgTag);
    snapStore.cursorPosition = cursorPt;

    drawToolMouseMoveThrottledBase(ev, this.svgTag, this.context);

    const { snapPoint, snapLineHorizontal, snapLineVertical } = snapStore;
    let newEndPt = cursorPt;

    // duplicate
    if (snapLineHorizontal) {
      newEndPt = new Point(
        newEndPt.x,
        snapLineHorizontal.points[1].y,
      );
    }

    if (snapLineVertical) {
      newEndPt = new Point(
        snapLineVertical.points[1].x,
        newEndPt.y,
      );
    }

    newEndPt.isSnappable = false;

    if (!isEmpty(this.selectedHole?.points)) {
      this.selectedHole.points[this.selectedHole.points.length - 1] = newEndPt;
    }

  }, MOUSE_MOVE_THROTTLE_TIME)

  _render() {
    return (
      <g id="AddHoleTool" ref={ref => this.svgTag = getSafe(() => ref.ownerSVGElement) || this.svgTag} />
    );
  }
}