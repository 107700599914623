import { computed, observable } from 'mobx';
import { map, primitive, serializable } from 'serializr';
import ModelBase from "./ModelBase";

// Wont be able to use @localized decorator here, because we use this object
// to initialize i18n.. might have to recheck the SettingsStore hasLocalization

export default class PriceUpdate extends ModelBase {
  type = 'PriceUpdate';

  @observable @serializable(map(primitive())) _priceMap = new Map<ModelId, number>();

  @observable @serializable(map(primitive())) _priceMapUpdatedMiliseconds = new Map<ModelId, number>();

  @observable @serializable(map(primitive())) _priceMapUnableToLoadMiliseconds = new Map<ModelId, number>();

  @computed get priceMap(): Map<ModelId, number> {
    const { providingItemsStore, userInfoStore, priceUpdateStore } = this.stores;
    const filteredMap = new Map(this._priceMap);

    filteredMap.forEach((value, key) => {
      // ensure we can delete from the map that we currently loop without getting an error
      const item = providingItemsStore.getItem(key);

      if (!item) {
        filteredMap.delete(key);
        return;
      }

      const updatedMiliseconds = priceUpdateStore.getApplicableUpdatedMiliseconds(item);

      // temp: use Url as a way to know if item is connected to a merchant (allows user to stop checking for updates)
      if (
        item.price === value ||
        !value ||
        !item.merchant ||
        !updatedMiliseconds ||
        item.priceUpdatedMiliseconds > updatedMiliseconds
      ) {
        filteredMap.delete(key);
      }
    });


    // sync from master cache data too
    // doesnt work for postal code
    /*providingItemsStore.userItemsWithMerchant
      .filter(i => i.cascadeOrders.has(0))
      .forEach(item => {
        const priceData = providingItemsStore.masterPriceData.get(item.id);
        if (priceData && item.priceUpdatedMiliseconds < priceData.priceUpdatedMiliseconds && item.price !== priceData.price) {
          filteredMap.set(item.id, priceData.price);
        }
    })*/

    // need to add USER items recently updated, not just itemsWithMerchant!
    if (userInfoStore.user?.shouldShowUserPriceUpdates) {
      Array.from(providingItemsStore.userPriceData.keys()).forEach(providingItemId => {
        const projectItem = providingItemsStore.getItem(providingItemId);
        const userItem = providingItemsStore.userPriceData.get(providingItemId);
        if (
          projectItem &&
          userItem &&
          !projectItem.merchant &&
          projectItem.price !== userItem.price &&
          projectItem.priceUpdatedMiliseconds < userItem.priceUpdatedMiliseconds
        ) {
          filteredMap.set(providingItemId, userItem.price);
        }
      });
    }

    return filteredMap;
  }

  set priceMap(value: Map<ModelId, number>) {
    this._priceMap = value;
  }

  @observable @serializable lastUpdatedItemId: ModelId = '';


  @computed get outdatedItems() {
    const { providingItemsStore } = this.stores;
    return providingItemsStore.allItemsByCategSubcategFlattened
      .filter(itemStruct => {
        const {item} = itemStruct;
        return (
          item &&
          this.priceMap.has(item.id) &&
          this._priceMapUpdatedMiliseconds.get(item.id) > item.priceUpdatedMiliseconds
        );
      })
      .map(itemStruct => itemStruct.item);
  }

  @computed get numberOfOutdatedPrices() {
    return this.outdatedItems.length;
  }
}