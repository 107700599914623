import { defer, isEqual, isFunction, isUndefined } from 'lodash';
import { ObservableMap, reaction } from 'mobx';
import { getDefaultModelSchema } from 'serializr';
import FirebaseStore from 'stores/FirebaseStore';

export function waitOnStoresReady(stores: FirebaseStore<any>[]): Promise<void> {
  return new Promise(resolve => {
    const unsubscribe = reaction(
      () => stores.map(store => store.isReady),
      (isReadyArray) => {
        defer(() => {
          if (isReadyArray.every(isReady => isReady)) {
            resolve();
            // seems to be undefined when fired immediately
            unsubscribe?.();
          }
        })
      },
      // in case stores are already ready
      { fireImmediately: true });
  });
}

// cascade order sometimes has unexpected results, because this is not only called in update from db
export function assignToExistingObject(existingObject, newObject, shouldTrackCreationDate = true) {
  let serializableProps = [];

  for (let schema = getDefaultModelSchema(existingObject); schema;) {
    serializableProps.push(Object.keys(schema.props));
    schema = schema.extends;
  }
  serializableProps = serializableProps.flat();
  serializableProps.push('_isDeleted');

  if (newObject && existingObject) {
    const assignableNewObject = {};

    serializableProps.forEach(key => {
      const value = newObject[key];

      if (
        // semi-hack, undefined values from db will be ignored so we can keep local value (not saved in db)
        isUndefined(value) ||
        isFunction(value) ||
        // 99% of the time, data returned from firebase after a db save will be the same as existing data
        // We skip assigning when possible because, it will cause chain reactions of observing components
        (value instanceof ObservableMap) && isEqual(Array.from(value), Array.from(existingObject[key])) ||
        isEqual(value, existingObject[key]) ||
        key === 'cascadeOrders' ||
        !shouldTrackCreationDate && key === 'createdMiliseconds'
      ) {
        return;
      }


      // priceFormula should normally be used and "price" is for legacy projects
      if (key == 'price' && newObject.priceFormula) {
        return;
      }

      assignableNewObject[key] = value;
    });

    //console.log(result.id, result.type, 'callback');
    Object.assign(
      existingObject,
      assignableNewObject
    );

    if (existingObject.cascadeOrders && newObject.cascadeOrders) {
      existingObject.cascadeOrders = new Set([...existingObject.cascadeOrders, ...newObject.cascadeOrders]);
    }
  }
}

