
import { Checkbox } from '@material-ui/core';
import classnames from 'classnames';
import SearchToolbar from 'components/common/SearchToolbar/SearchToolbar';
import { CategoryType } from 'constants/CategoryType';
import TasksList from 'models/TasksList';
import * as React from 'react';
import i18n from 'utils/i18n';
import GroupedList from '../GroupedList/GroupedList';
import ObserverComponent from '../ObserverComponent';
import TasksListComponent from '../TasksListComponent/TasksListComponent';

const styles = require('./TasksListsList.module.scss');

class TasksListItem extends ObserverComponent<{ item: TasksList }> {
  onDragStart = (tasksList: TasksList) => {
    const { dragAndDropStore } = this.context;
    dragAndDropStore.dragObject = tasksList;
  }

  onDragEnd = (event: React.DragEvent<HTMLElement>) => {
    const { dragAndDropStore } = this.context;
    dragAndDropStore.dragObject = null;
  }

  _render() {
    const { tasksListsStore, settingsStore } = this.context;
    const { item } = this.props;

    return (
      <div
        id={item.id}
        className={classnames(styles.listItem, { 
          [styles.isEditing]: tasksListsStore.tasksListBeingEdited === item,
          [styles.isTouchDevice]: settingsStore?.isTouchDevice
        })}
        draggable={tasksListsStore.tasksListBeingEdited !== item}
        onDragStart={(event) => {
          // drag wont work on touch screen with mouse unless we set this even if we don't use it
          event.dataTransfer.setData("text/plain", item.id);
          this.onDragStart(item);
        }}
        onDragEnd={this.onDragEnd}
      >
        <TasksListComponent tasksList={item} />
      </div>
    );
  }
}


export default class TasksListsList extends ObserverComponent<{}> {
  _render() {
    const { tasksListsStore } = this.context;

    return (
      <div className={styles.root}>
        <div>
          <SearchToolbar
            store={tasksListsStore}
            categoryType={CategoryType.TasksList}
            shouldFilterBySubcategories
            optionsMenuItems={[{
              icon: <Checkbox checked={tasksListsStore.shouldShowHiddenItems} />,
              text: i18n.t('Show archived lists'),
              handler: () => { tasksListsStore.shouldShowHiddenItems = !tasksListsStore.shouldShowHiddenItems },
            }, {
              icon: <Checkbox checked={tasksListsStore.shouldMarkMasterItems} />,
              text: i18n.t('Identify original Evalumo lists'),
              handler: () => { tasksListsStore.shouldMarkMasterItems = !tasksListsStore.shouldMarkMasterItems },
            }]}
          />
        </div>

        <GroupedList
          ungroupedItems={tasksListsStore.searchedItems}
          itemsByCategSubcategFlattened={tasksListsStore.itemsByCategSubcategFlattened}
          className={styles.list}
          store={tasksListsStore}
          ItemRenderer={TasksListItem}
          shouldAllowCheckboxes
          shouldAllowReorderItems
          innerRef={ref => tasksListsStore.listComponentRef = ref}
          onChangeItemCategory={item => tasksListsStore.openChangeCategoryDialog([item])}
        />
      </div >
    )
  }
}