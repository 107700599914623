import { Badge, Button, Tab, Tabs } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';
import CategoryIcon from '@material-ui/icons/Category';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import MerchantIcon from '@material-ui/icons/Store';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ToolsIcon from '@material-ui/icons/Work';
import classnames from 'classnames';
import MerchantIframe from 'components/common/MerchantIframe/MerchantIframe';
import MyItems from 'components/common/MyItems/MyItems';
import Panel from 'components/common/Panel/Panel';
import TasksListsList from 'components/common/TasksListsList/TasksListsList';
import { CategoryType } from 'constants/CategoryType';
import { ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { RightBarTabs } from 'constants/RightBarTabs';
import { compact, isEmpty } from 'lodash';
import Dialog from 'models/Dialog';
import Task from 'models/Task';
import * as React from 'react';
import { archiveItems, deleteItems, unarchiveItems } from 'utils/DeleteUtils';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { addNewMeasurementToTask } from 'utils/MeasurementUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { MagicIcon } from '../Icons';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';

const styles = require('./RightBar.module.scss');
const colors = require('../../../Colors.scss');

interface IRightBarProps {
  shouldShowOnlyMyItems?: boolean,
  shouldHideLists?: boolean,
}

const getTabIcon = (selectedTab: RightBarTabs) => {
  switch (selectedTab) {
    case RightBarTabs.LISTS:
      return <MagicIcon />;
    case RightBarTabs.MY_ITEMS:
      return <ToolsIcon />;
    default:
      return <MerchantIcon />;
  }
}

const getTabText = (selectedTab: RightBarTabs) => {
  switch (selectedTab) {
    case RightBarTabs.LISTS:
      return i18n.t('Lists');
    case RightBarTabs.MY_ITEMS:
      return i18n.t('My items');
    default:
      return i18n.t('Shop');
  }
}

const getCategoryType = (selectedTab: RightBarTabs, providingItemSubtype: ProvidingItemSubtype) => {
  return selectedTab === RightBarTabs.MY_ITEMS
    ? (
      providingItemSubtype === ProvidingItemSubtype.Labour
        ? CategoryType.Labour
        : CategoryType.Material
    ) : CategoryType.TasksList;
}

const getSelectedTab = (selectedTabFromSettings: RightBarTabs, shouldShowOnlyMyItems = false, shouldHideLists = false) => {
  if (
    shouldShowOnlyMyItems ||
    shouldHideLists && selectedTabFromSettings === RightBarTabs.LISTS
  ) {
    return RightBarTabs.MY_ITEMS;
  }

  return selectedTabFromSettings;
}

export default class RightBar extends ObserverComponent<IRightBarProps> {
  handleChangeTab = (event, selectedTab) => {
    const { settingsStore } = this.context;
    settingsStore.settings.selectedRightBarTab = selectedTab;
    //settingsStore.addEditItemDebounced(settingsStore.settings);
  };

  handleOpenChangeCategoryDialog = () => {
    const { shouldShowOnlyMyItems, shouldHideLists } = this.props;
    const { settingsStore, providingItemsStore, tasksListsStore } = this.context;

    const selectedTab = getSelectedTab(settingsStore.settings.selectedRightBarTab, shouldShowOnlyMyItems, shouldHideLists);

    if (selectedTab === RightBarTabs.MY_ITEMS) {
      providingItemsStore.openChangeCategoryDialog();
    } else {
      tasksListsStore.openChangeCategoryDialog();
    }
  }

  handleOpenChangeSubtypeDialog = (subtype: ProvidingItemSubtype) => () => {
    const { dialogsStore, providingItemsStore } = this.context;
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <ConfirmDialog
        dialogId={newDialog.id}
        open={open}
        title={i18n.t(
          subtype === ProvidingItemSubtype.Material
            ? 'moveItemsToLabour'
            : 'moveItemsToMaterial',
          { count: providingItemsStore.selectedItemsArray.length }
        )}
        onConfirm={() => {
          providingItemsStore.selectedItemsArray.forEach(item => {
            item.subtype = subtype === ProvidingItemSubtype.Material ? ProvidingItemSubtype.Labour : ProvidingItemSubtype.Material;
          });
          providingItemsStore.addEditItems(providingItemsStore.selectedItemsArray);
          providingItemsStore.selectedItems.clear();
        }}
        yesLabel={i18n.t('Move')}
        noLabel={i18n.t('Cancel')}
      />
    );
    dialogsStore.showDialog(newDialog);
  }

  addSelectedItemsAsTasks = () => {
    const { providingItemsStore, treeNodesStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;
    const items = providingItemsStore.selectedItemsArray;

    const batch = firestoreBatch(this.context);

    const newTasksIds = items.map(item => {
      const task = new Task(this.context);
      task.providingItemId = item.id;

      if (item.category.categoryTypes.includes(CategoryType.Task)) {
        task.category = item.category;
      }

      // this saves the task to db
      addNewMeasurementToTask(task, this.context, batch);

      return task.id;
    });

    selectedTreeNode.ownTasksIds.push(...newTasksIds);
    treeNodesStore.batchAddEditItem(selectedTreeNode, batch);
    providingItemsStore.ensureSavedInProjectCollection(items, batch);
    batch.commit();

    providingItemsStore.selectedItems.clear();
  }

  _render() {
    const { priceUpdateStore, settingsStore, providingItemsStore, tasksListsStore, routerStore } = this.context;
    const { shouldShowOnlyMyItems, shouldHideLists } = this.props;

    const selectedTab = getSelectedTab(settingsStore.settings.selectedRightBarTab, shouldShowOnlyMyItems, shouldHideLists);

    if (routerStore.isRescueMode) {
      return null;
    }

    if (shouldShowOnlyMyItems) {
      return <MyItems />;
    }

    const store = selectedTab === RightBarTabs.MY_ITEMS
      ? providingItemsStore
      : tasksListsStore;

    const hasItemsSelected = store.selectedItems.size > 0;
    const selectedSubtype = getSafe(() => store.selectedItemsArray[0].subtype) as ProvidingItemSubtype;
    const isMaterial = selectedSubtype === ProvidingItemSubtype.Material && store == providingItemsStore;
    const isLabour = selectedSubtype === ProvidingItemSubtype.Labour && store == providingItemsStore;

    const menuOptions = compact([{
      icon: <CategoryIcon />,
      text: isMaterial
        ? i18n.t('Move to a different Material category')
        : isLabour
          ? i18n.t('Move to a different Labour & Fees category')
          : i18n.t('Move to a different category') + '...',
      handler: this.handleOpenChangeCategoryDialog
    }, (isMaterial || isLabour) && {
      icon: <ArrowRightIcon />,
      text: isMaterial ? i18n.t('Move to Labour & Fees') : i18n.t('Move to Material'),
      handler: this.handleOpenChangeSubtypeDialog(selectedSubtype)
    },
    (isMaterial || isLabour) && store.selectedItems.size > 1 && store.selectedItems.size < 200 && {
      icon: <ReplyIcon style={{ transform: 'scaleX(-1) rotate(180deg)' }} />,
      text: i18n.t('Add as tasks'),
      handler: this.addSelectedItemsAsTasks
    },
    { icon: <DeleteIcon />, text: i18n.t('Delete forever'), handler: () => deleteItems(store.selectedItemsArray, store), danger: true },
  ]);

    return (
      <Panel
        className={styles.root}
        header={
          hasItemsSelected
            ? (
              <div className={styles.selectionActions} >
                {getTabIcon(selectedTab)}
                <div>{getTabText(selectedTab)}</div>
                <div className={styles.buttons}>
                  <Button className={styles.cancelButton} onClick={() => store.selectedItems.clear()}>
                    {i18n.t('Cancel')}
                  </Button>

                  {store.selectedItemsArray.every(project => project.isHidden) ? (
                    <Button
                      onClick={() => unarchiveItems(store.selectedItemsArray, store)}
                      className={styles.archiveButton}
                    >
                      <UnarchiveIcon />
                      {i18n.t(`Unarchive {{numProjects}} ${selectedTab === RightBarTabs.LISTS ? 'list' : 'item'}(s)`, { numProjects: store.selectedItemsArray.length })}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => archiveItems(store.selectedItemsArray, store)}
                      className={styles.archiveButton}
                    >
                      <ArchiveIcon />
                      {i18n.t(`Archive {{numProjects}} ${selectedTab === RightBarTabs.LISTS ? 'list' : 'item'}(s)`, { numProjects: store.selectedItemsArray.length })}
                    </Button>
                  )}

                  <MenuPopupButton
                    className={styles.moreButton}
                    menuItems={
                      menuOptions || [
                        { icon: <CategoryIcon />, text: i18n.t('Move to a different category') + '...', handler: this.handleOpenChangeCategoryDialog },
                      ]} />
                </div>
              </div>
            ) : (
              <Tabs
                className={styles.tabs}
                value={selectedTab}
                onChange={this.handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                {/*todo: add tooltip */}
                <Tab icon={getTabIcon(RightBarTabs.SHOP)} label={getTabText(RightBarTabs.SHOP)} />

                <Tab icon={
                  <Badge
                    invisible={priceUpdateStore.isPaused || (
                      isEmpty(priceUpdateStore.itemsToUpdate) && isEmpty(priceUpdateStore.unsyncableItems)
                    )}
                    className={styles.inProgressBadge}
                    variant="dot"
                  >
                    {getTabIcon(RightBarTabs.MY_ITEMS)}
                  </Badge>
                } style={{ overflow: 'visible' }}
                  label={
                    <Badge
                      className={styles.itemsBadge}
                      badgeContent={priceUpdateStore.numberOfOutdatedPrices}
                    >
                      {getTabText(RightBarTabs.MY_ITEMS)}
                    </Badge>
                  }
                />


                {!shouldHideLists && <Tab icon={getTabIcon(RightBarTabs.LISTS)} label={getTabText(RightBarTabs.LISTS)} />}
              </Tabs>
            )
        }
        content={
          compact([
            <div className={classnames(styles.tabContent, styles.merchantIframeContainer)} hidden={selectedTab !== RightBarTabs.SHOP} key="rightbar1" >
              <MerchantIframe />
            </div >,
            <div className={styles.tabContent} hidden={selectedTab !== RightBarTabs.MY_ITEMS} key="rightbar2">
              <MyItems />
            </div>,
            !shouldHideLists && (
              <div className={styles.tabContent} hidden={selectedTab !== RightBarTabs.LISTS} key="rightbar3">
                <TasksListsList />
              </div>
            ),
          ])}
      />
    )
  }
}